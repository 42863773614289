import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../api/types";
import { ReactComponent as StarIcon } from "../../../assets/content/star.svg";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";
import { ShoppingCartContext } from "../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";
import HoverButton from "../../Button/HoverButton";
import StandardTooltip from "../../Tooltip";
import {
  AlertContainer,
  BestProductCard,
  BestProductContent,
  BestProductImage,
  BestProductInformation,
  BrandName,
  ButtonContainer,
  CompatibilityLabel,
  IconsContainer,
  ImageContainer,
  PriceLabel,
  ProductName,
  StarContainer,
} from "./style";

interface BestProductsCardProps {
  product: ProductProps;
  color?: "primary" | "secondary";
}

const BestProductsCard: React.FC<BestProductsCardProps> = ({
  product,
  color = "primary",
}) => {
  const {
    brand,
    isInPack,
    hasPrecaution,
    hasSynergy,
    price,
    currency,
    score,
    name,
    imageUrl,
    alerts,
  } = product;

  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleNavigate = () => {
    navigation("/product-details", {
      state: product,
      // replace: true,
    });
  };

  const { addProduct, isProductAlreadyAdded, handleOpenRemoveProductModal } =
    useContext(ShoppingCartContext);

  const addToCart = () => {
    if (isProductAlreadyAdded(product)) {
      handleOpenRemoveProductModal(product);
    } else {
      addProduct(product, 1);
    }
  };

  return (
    <BestProductCard color={color}>
      <BestProductContent>
        <ImageContainer onClick={handleNavigate}>
          <BestProductImage
            src={getImageCompletePath("product", imageUrl)}
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
        </ImageContainer>
        <BestProductInformation onClick={handleNavigate}>
          <BrandName>{brand}</BrandName>
          <ProductName>{name}</ProductName>
          {/* <CompatibilityLabel value={score}>
            {t("screens.nutrientDetail.your_compatibility")}{" "}
            <label>{`${Math.round(score)}/100`}</label>
          </CompatibilityLabel> */}
          <PriceLabel>{`${getCurrencyFormat(currency, price)}`}</PriceLabel>
          <IconsContainer>
            {isInPack && (
              <StarContainer id={`product-is-in-top-ten-${product.id}`}>
                <StarIcon />
              </StarContainer>
            )}
            {isInPack && (
              <StandardTooltip
                anchor={`product-is-in-top-ten-${product.id}`}
                color="blue"
                text={t("hovers.product_is_recommended")}
                place="bottom"
              />
            )}
            {hasPrecaution && (
              <AlertContainer id={`product-has-alert-${product.id}`}>
                <IoAlertOutline color="white" />
              </AlertContainer>
            )}
            {hasPrecaution && (
              <StandardTooltip
                anchor={`product-has-alert-${product.id}`}
                color="orange"
                text={t("hovers.product_has_alert")}
                place="bottom"
              />
            )}
          </IconsContainer>
        </BestProductInformation>

        <ButtonContainer>
          {/* <PrimaryButton
            label="Add To Cart"
            onClick={addToCart}
            width={"248px"}
            color={color === "secondary" ? "blue" : "green"}
          /> */}
          <HoverButton
            isActive={isProductAlreadyAdded(product)}
            label={
              isProductAlreadyAdded(product)
                ? t("screens.nutrientDetail.in_cart")
                : t("screens.nutrientDetail.add_to_cart")
            }
            onClick={addToCart}
          />
        </ButtonContainer>
      </BestProductContent>
    </BestProductCard>
  );
};

export default BestProductsCard;
