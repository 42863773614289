import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #e6e7ec;
  display: flex;
  flex-direction: column;
  width: 100%;

  background: #fff;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //   border: 2px solid red;
  //   height: 36px;
  //   > svg {
  //     height: 50px;
  //     width: 50px;
  //     // border: 2px solid orange;
  //   }

  //   > * {
  //     border: 2px solid orange;
  //   }

  > img {
    height: 38px;
    width: auto;
  }

  > label {
    font-size: 16px;
    font-weight: bold;
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Progress = styled.div<{ height?: number }>`
  margin-top: 13px;
  height: ${({ height }) => height ?? `15`}px;
  width: 100%;
  border-radius: 50px;
  background-color: #f5f7fe;
`;

export const Bar = styled.div<{
  progress: number;
  isContraindication?: boolean;
}>`
  background: ${({ progress, isContraindication }) =>
    progress < 0 || isContraindication
      ? `linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%)`
      : `linear-gradient(269.62deg, #0fa986 18.05%, #007acc 103.31%)`};
  height: 100%;
  width: ${({ progress }) => progress}%;
  border-radius: inherit;
`;
