import styled from "styled-components";

export const Container = styled.div<{ isCollapseOpen: boolean }>`
  // width: 50%;
  width: calc(50% - 4px);
  // margin-right: 8px;
  height: auto;
  max-height: ${({ isCollapseOpen }) => (!isCollapseOpen ? "259px;" : "auto")};
  overflow: hidden;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.lightShadow}
  // margin-right: 8px;
  // border: 2px solid black;
  position: relative;
  margin-top: 16px;
  padding: 24px;
  // transition: max-height 5s ease-out;
`;

export const CollapseContent = styled.div<{ isCollapseOpen: boolean }>`
  opacity: 0;
  transition: opacity 0.5s ease-in;
  // border: 2px solid red;
  ${({ isCollapseOpen }) => isCollapseOpen && `opacity: 1`};

  > div {
    :not(:last-child) {
      border-bottom: 1px solid #dee0e5;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  // border: 2px solid red;
`;

export const Section = styled.section`
  margin-top: 80px;
  //   border: 2px solid black;
  //   height: 76px;
  background: #f5f7fe;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const SectionTitle = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  height: 50%;
  width: auto;

  align-items: flex-start;
  //   margin-bottom: 10px;
  > svg {
    fill: ${({ theme }) => theme.colors.white};
    height: 40px;
    width: 40px;
  }
`;

export const CompositionField = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  // border: 2px solid red;

  align-items: flex-start;
  //   margin-bottom: 10px;
  // margin-left: 2px;
  > svg {
    fill: ${({ theme }) => theme.colors.white};
    height: 40px;
    width: 40px;

    // border: 2px solid orange;
  }
`;

export const HealthCategoryName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.black};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // border: 2px solid red;

  width: 60%;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  //   margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);

  position: absolute;
  left: -8px;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 39px;
    margin-top: 1px;
    margin-right: 1px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  //   border: 2px solid red;
`;

export const Footer = styled.div`
  //   border: 2px solid red;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};

  > span {
    color: #235ed5;
  }

  > label {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const CollapseButton = styled.div`
  //   border: 2px solid green;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    height: 25px;
    width: 25px;
  }
  > label {
    margin-right: 12px;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    // line-height: 39px;
    color: ${({ theme }) => theme.colors.selected};
  }
`;
