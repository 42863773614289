import React from "react";
import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { NutrientProps } from "../../../../api/types";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import StandardTooltip from "../../../../components/Tooltip";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";
import {
  AlertContainer,
  ButtonContainer,
  CardContainer,
  CardContent,
  CardHeader,
  Compatibility,
  ImageContainer,
  NutrientName,
  ProductIconsContainer,
  StarContainer,
  TitleContainer,
} from "./style";
interface CardNutrientProps {
  nutrient: NutrientProps;
  onClick: () => void;
}

const Card = React.forwardRef<HTMLDivElement, CardNutrientProps>(
  ({ nutrient, onClick }, ref) => {
    const { t } = useTranslation();

    const {
      name,
      score,
      hasPrecaution,
      hasSynergy,
      image,
      dosageAmountValue,
      isInTop,
      dosageAmountUnit,
    } = nutrient;

    const executeBestProductsScroll = () => {
      onClick();
      if (ref !== null) {
        // @ts-ignore
        setTimeout(() => ref.current.scrollIntoView(), 100);
      }
    };

    return (
      <CardContainer horizontal>
        <ImageContainer>
          <img
            src={getImageCompletePath("nutrient", image)}
            alt="product"
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
        </ImageContainer>
        <CardContent>
          <CardHeader>
            <TitleContainer>
              <NutrientName>
                {`${name}`}
                <label>{` - ${dosageAmountValue} ${dosageAmountUnit}/${t(
                  "screens.nutrientDetail.day"
                )}`}</label>{" "}
              </NutrientName>
              {/* <Compatibility value={Math.round(score)}>
                {t("screens.nutrientDetail.your_compatibility")}
                <span>{`${Math.round(score)}/100`}</span>
              </Compatibility> */}
              <ButtonContainer>
                <PrimaryButton
                  label={t("screens.nutrientDetail.products_containing")}
                  onClick={executeBestProductsScroll}
                />
              </ButtonContainer>
            </TitleContainer>
            <ProductIconsContainer>
              {isInTop && (
                <StarContainer id={`nutrient-is-in-top-ten-${nutrient.id}`}>
                  <StarIcon />
                </StarContainer>
              )}
              {isInTop && (
                <StandardTooltip
                  anchor={`nutrient-is-in-top-ten-${nutrient.id}`}
                  color="blue"
                  text={t("hovers.nutrient_is_recommended")}
                  place="bottom"
                />
              )}
              {hasPrecaution && (
                <AlertContainer id={`nutrient-has-alert-${nutrient.id}`}>
                  <IoAlertOutline color="white" />
                </AlertContainer>
              )}
              {hasPrecaution && (
                <StandardTooltip
                  anchor={`nutrient-has-alert-${nutrient.id}`}
                  color="orange"
                  text={t("hovers.nutrient_has_alert")}
                  place="bottom"
                />
              )}
            </ProductIconsContainer>
          </CardHeader>
        </CardContent>
      </CardContainer>
    );
  }
);

export { Card };
