import { Container } from './style';

interface CircleNumberProps {
  number: number
}
export function CircleNumber({ number }: CircleNumberProps) {
  return (
    <>
      <Container>
         {number}
      </ Container>
    </>
  )
}