import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoAlertOutline, IoCheckmarkCircleOutline } from "react-icons/io5";

import { useNavigate } from "react-router";
import { ProductProps } from "../../../api/types";
import { ReactComponent as StarIcon } from "../../../assets/content/star.svg";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";
import { ShoppingCartContext } from "../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import { getHealthGoalsIcon } from "../../../utils/getHealthGoalsIcon";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";
import handleImportance from "../../../utils/getImportance";
import { getSymptomsIcon } from "../../../utils/getSymptomsIcon";
import { handleColorTooltipRecommended } from "../../../utils/handleColorTooltipRecommended";
import { handleIsTheMostRecommended } from "../../../utils/handleIsTheMostRecomended";
import RoundedProgress from "../../RoundedProgress";
import StandardTooltip from "../../Tooltip";
import TooltipAlert from "../../TooltipAlert";

import {
  AddProductButton,
  AlertContainer,
  Body,
  BrandName,
  Circle,
  Container,
  Footer,
  Header,
  HealthGoalAndSymptomContent,
  HealthGoalAndSymptomScoreProgress,
  HealthGoalAndSymptomTitle,
  HealthGoalsAndSymptomsContainer,
  HealthGoalsAndSymptomsIcon,
  NoneSubtitle,
  NoneTitle,
  Product,
  ProductInfo,
  ProductName,
  StarContainer,
  Subtitle,
} from "./style";

interface CardProduct {
  product: ProductProps;
}

const InfoProduct: React.FC<CardProduct> = ({ product }) => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const { addOneProduct, isProductAlreadyAdded, handleOpenRemoveProductModal } =
    useContext(ShoppingCartContext);

  const {
    name,
    brand,
    isInPack,
    hasPrecaution,
    hasSynergy,
    healthGoals,
    symptoms,
    capsuleAmount,
    imageUrl,
    price,
    currency,
    score,
    alerts,
  } = product;

  const addToShoppingCart = () => {
    if (isProductAlreadyAdded(product)) {
      handleOpenRemoveProductModal(product);
    } else {
      addOneProduct(product);
    }
  };

  const showAlert =
    hasPrecaution ||
    alerts.componentsAllergy.length > 0 ||
    alerts.componentsIntolerance.length > 0 ||
    alerts.excipientAllergy.length > 0 ||
    alerts.excipientIntolerance.length > 0;

  return (
    <Container>
      <Header
        onClick={() => {
          navigation("/product-details", {
            state: product,
            // replace: true,
          });
        }}
      >
        {/* <CapsuleContainer>
          <CapsuleIcon />
          <label>{capsuleAmount}</label>
        </CapsuleContainer> */}
        <Product
          src={getImageCompletePath("product", imageUrl)}
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
        <ProductInfo>
          {(isInPack || score >= 80) && (
            <StarContainer
              id={`product-is-in-top-ten-${product.id}`}
              isTheMostRecommended={handleIsTheMostRecommended(score)}
            >
              <StarIcon />
            </StarContainer>
          )}
          {(isInPack || score >= 80) && (
            <StandardTooltip
              anchor={`product-is-in-top-ten-${product.id}`}
              color={handleColorTooltipRecommended(score)}
              text={
                score >= 80
                  ? t("hovers.product_is_highly_recommended")
                  : t("hovers.product_is_recommended")
              }
              place="bottom"
            />
          )}
          {showAlert && (
            <AlertContainer
              id={`this-product-has-alert-${product.id}`}
              importance={handleImportance(product.alerts)}
            >
              <IoAlertOutline color="white" />
            </AlertContainer>
          )}
          {showAlert && (
            <TooltipAlert
              anchor={`this-product-has-alert-${product.id}`}
              color={handleImportance(product.alerts)}
              text={t("hovers.product_has_alert")}
              place="bottom"
            />
          )}
        </ProductInfo>
      </Header>
      <Body
        onClick={() => {
          navigation("/product-details", {
            state: product,
            // replace: true,
          });
        }}
      >
        <BrandName>{`${brand ? brand : `⠀⠀`}`}</BrandName>
        <ProductName>{name}</ProductName>
        {/* <ProductCompability value={score}>
          {t("screens.home.tabs.bestProducts.your_compatibility")}
          <span>{`${Math.round(score)}/100`}</span>
        </ProductCompability> */}
        <HealthGoalsAndSymptomsContainer>
          {healthGoals.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>
                    {t("screens.home.tabs.bestProducts.health_goals")}
                  </label>
                  <Circle>
                    <label>{healthGoals.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <Subtitle>{t(`healthGoals.${healthGoals[0].name}`)}</Subtitle>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={healthGoals[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getHealthGoalsIcon(healthGoals[0].name, 22)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {symptoms.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>{t("screens.home.tabs.bestProducts.symptoms")}</label>
                  <Circle>
                    <label>{symptoms.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <Subtitle>{symptoms[0].name}</Subtitle>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={symptoms[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getSymptomsIcon(symptoms[0].categoryId)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {healthGoals.length === 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <NoneTitle />
                </HealthGoalAndSymptomTitle>
                <span>
                  <NoneSubtitle />
                </span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={0}
                  thickness={5}
                  grayForeground
                  backgroundColor="#f5f7fe"
                >
                  <div></div>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {symptoms.length === 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <NoneTitle />
                </HealthGoalAndSymptomTitle>
                <span>
                  <NoneSubtitle />
                </span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={0}
                  thickness={5}
                  grayForeground
                  backgroundColor="#f5f7fe"
                >
                  <div></div>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
        </HealthGoalsAndSymptomsContainer>
      </Body>
      <Footer>
        <div
          onClick={() => {
            navigation("/product-details", {
              state: product,
            });
          }}
        >
          <label>{t("screens.home.tabs.bestProducts.learn_more")}</label>
        </div>
        <div>
          <AddProductButton
            onClick={addToShoppingCart}
            added={isProductAlreadyAdded(product)}
          >
            <label>
              {isProductAlreadyAdded(product)
                ? t("screens.home.tabs.bestProducts.in_cart")
                : `${getCurrencyFormat(currency, price)}`}
            </label>

            {isProductAlreadyAdded(product) ? (
              <IoCheckmarkCircleOutline color="white" size={22} />
            ) : (
              <IoIosAddCircleOutline size={22} />
            )}
          </AddProductButton>
        </div>
      </Footer>
    </Container>
  );
};
export default InfoProduct;
