import React, { createContext, useContext, useEffect, useState } from "react";
import { ProductProps } from "../api/types";
import { RemoveProductModal } from "../components/Modal/RemoveProduct";
import { ShoppingCartSidebar } from "../components/Sidebar/ShoppingCartSidebar";
import GradientSVG from "../styles/roundedProgress/gradient";
import { GlobalContext } from "./global";

interface ShoppingCartContextInterface {
  shoppingCart: ShoppingCartItem[];
  addPack: (pack: ProductProps[]) => void;
  addPackByDuration: (pack: ProductProps[], months: number) => void;
  addProduct: (productToAdd: ProductProps, amount: number) => void;
  addOneProduct: (productToAdd: ProductProps) => void;
  removeProduct: (
    productToRemove: ProductProps,
    amountToRemove: number
  ) => void;
  removeOneProduct: (productToRemove: ProductProps) => void;
  isPackAlreadyAdded: (pack: ProductProps[]) => boolean;
  isPackAlreadyAddedWithDuration: (
    pack: ProductProps[],
    months: number
  ) => boolean;
  isProductAlreadyAdded: (product: ProductProps) => boolean;
  isProductAlreadyAddedSameAmount: (
    product: ProductProps,
    amount: number
  ) => boolean;
  openShoppingCartSidebar: () => void;
  closeShoppingCartSidebar: () => void;
  isShoppingCartSidebarOpen: boolean;
  inCartNutritionalProgress: number;
  getPrice: () => number;
  getPriceOfPackByDuration: (pack: ProductProps[], months: number) => number;
  handleOpenRemoveProductModal: (productToRemove: ProductProps) => void;
  handleCloseRemoveProductModal: () => void;
  isRemoveProductModalOpen: boolean;
  productToRemove: ProductProps | undefined;
  changeAmountOfProduct: (newAmount: number, product: ProductProps) => void;
  getAmountOfProduct: (product: ProductProps) => number;
  clearShoppingCart: () => void;
  getTotalProducts: () => number;
}

export const ShoppingCartContext = createContext(
  {} as ShoppingCartContextInterface
);

export type ShoppingCartItem = {
  product: ProductProps;
  amount: number;
};

const ShoppingCartProvider = ({ children }: { children: React.ReactNode }) => {
  const { premiumPack } = useContext(GlobalContext);
  const [shoppingCart, setShoppingCart] = useState<ShoppingCartItem[]>([]);
  const [productIdList, setProductIdList] = useState<number[]>([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openRemoveProductModal, setOpenRemoveProductModal] = useState(false);
  const [productToRemove, setProductToRemove] = useState<
    ProductProps | undefined
  >();

  const [inCartNutritionalProgress, setInCartNutritionalProgress] = useState(0);
  const [maxScore, setMaxScore] = useState(0);

  useEffect(() => {
    const _maxScore = premiumPack
      .map((product) => product.score)
      .reduce((accumulator, current) => accumulator + current, 0);
    setMaxScore(_maxScore);
  }, [premiumPack]);

  function getTotalProducts() {
    const totalItems = shoppingCart.reduce((total, product) => total + product.amount, 0);
    return totalItems
  }

  const isProductAlreadyAdded = (product: ProductProps) =>
    productIdList.includes(product.id);

  const getAmountOfProduct = (product: ProductProps) => {
    const indexOf = productIdList.indexOf(product.id);
    if (!(indexOf >= 0)) {
      return 0;
    }

    return shoppingCart[indexOf].amount;
  };

  const isProductAlreadyAddedSameAmount = (
    product: ProductProps,
    amount: number
  ) => {
    const indexOf = productIdList.indexOf(product.id);

    if (!(indexOf >= 0)) {
      return false;
    }

    return shoppingCart[indexOf].amount >= amount;
  };

  function isPackAlreadyAdded(pack: ProductProps[]) {
    const found = pack.every((product) => productIdList.includes(product.id));

    return found;
  }

  function isPackAlreadyAddedWithDuration(
    pack: ProductProps[],
    months: number
  ) {
    const found = pack.every((product) => {
      return isProductAlreadyAddedSameAmount(
        product,
        calculateAmountOfProductsByMonths(product, months)
      );
    });

    return found;
  }

  const getPriceOfPackByDuration = (pack: ProductProps[], months: number) => {
    const packPriceByDuration = pack
      .map(
        (product) =>
          product.price * calculateAmountOfProductsByMonths(product, months)
      )
      .reduce((acc, cur) => acc + cur, 0);
    return packPriceByDuration;
  };

  const calculateAmountOfProductsByMonths = (
    product: ProductProps,
    months: number
  ) => {
    const productDurationInDays = product.capsuleAmount / product.capsuleDosage;
    const productDurationInMonths = productDurationInDays / 30;

    const amountNecessary = months * (1 / productDurationInMonths);

    return Math.ceil(amountNecessary);
  };

  const getPrice = () =>
    shoppingCart.length > 0
      ? shoppingCart
        .map((item) => item.product.price * item.amount)
        .reduce((accumulator, current) => accumulator + current, 0)
      : 0;

  useEffect(() => {
    if (shoppingCart.length > 0) {
      const cartScore = shoppingCart
        .map((item) => item.product.score)
        .reduce((acc, cur) => acc + cur, 0);

      const nutritionalProgress = (cartScore / maxScore) * 100;

      setInCartNutritionalProgress(
        nutritionalProgress > 100 ? 100 : Math.round(nutritionalProgress)
      );
    } else {
      setInCartNutritionalProgress(0);
    }
  }, [shoppingCart, maxScore]);

  const clearShoppingCart = () => {
    setProductIdList([]);
    setShoppingCart([]);
    setOpenSidebar(false);
  };

  const openShoppingCartSidebar = () => {
    setOpenSidebar(true);
  };

  const closeShoppingCartSidebar = () => {
    setOpenSidebar(false);
  };

  const handleOpenRemoveProductModal = (product: ProductProps) => {
    setProductToRemove(product);
    setOpenRemoveProductModal(true);
  };

  const handleCloseRemoveProductModal = () => {
    setProductToRemove(undefined);
    setOpenRemoveProductModal(false);
  };

  const addProduct = (productToAdd: ProductProps, amount: number) => {
    if (isProductAlreadyAdded(productToAdd)) {
      changeAmountOfProduct(amount, productToAdd);
      return;
    }

    setProductIdList((old) => [...old, productToAdd.id]);
    setShoppingCart((old) => [
      ...old,
      {
        product: productToAdd,
        amount,
      },
    ]);
  };

  const addOneProduct = (productToAdd: ProductProps) => {
    addProduct(productToAdd, 1);
  };

  const removeProduct = (
    productToRemove: ProductProps,
    amountToRemove: number
  ) => {
    if (!isProductAlreadyAdded(productToRemove)) {
      return;
    }

    const cartItemPosition = productIdList.indexOf(productToRemove.id);

    const newShoppingCart = shoppingCart
      .map((item, index) => {
        if (cartItemPosition === index) {
          if (item.amount - amountToRemove < 1) {
            // Só resta um?
            setProductIdList((old) =>
              old.filter((id) => id !== productToRemove.id)
            );
            return undefined;
          }
          // Se restar mais:
          return {
            ...item,
            amount: item.amount - amountToRemove,
          };
        } else {
          return item;
        }
      })
      .filter((item) => !!item);
    // @ts-ignore
    setShoppingCart(newShoppingCart);

    if (newShoppingCart.length === 0) {
      setOpenSidebar(false);
    }
  };

  const removeOneProduct = (productToRemove: ProductProps) => {
    removeProduct(productToRemove, 1);
  };

  const addPack = (pack: ProductProps[]) => {
    pack.forEach((product) => {
      addProduct(product, 1);
    });
  };

  const addPackByDuration = (pack: ProductProps[], months: number) => {
    const newShoppingCart = shoppingCart;
    const newProductIdList = productIdList;

    pack.forEach((product) => {
      if (!newProductIdList.includes(product.id)) {
        newShoppingCart.push({
          amount: calculateAmountOfProductsByMonths(product, months),
          product,
        });
        newProductIdList.push(product.id);
      } else {
        const indexOf = newShoppingCart
          .map((elem) => elem.product.id)
          .indexOf(product.id);

        newShoppingCart[indexOf] = {
          amount: calculateAmountOfProductsByMonths(product, months),
          product,
        };
      }
    });
    setShoppingCart((old) => [...newShoppingCart]);
    setProductIdList(newProductIdList);
  };

  const changeAmountOfProduct = (newAmount: number, product: ProductProps) => {
    const indexOf = shoppingCart
      .map((elem) => elem.product.id)
      .indexOf(product.id);

    setShoppingCart(
      shoppingCart.map((elem, index) => {
        if (index === indexOf) {
          return {
            amount: newAmount,
            product,
          };
        } else {
          return elem;
        }
      })
    );
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        addProduct,
        addOneProduct,
        removeProduct,
        removeOneProduct,
        isProductAlreadyAdded,
        isPackAlreadyAdded,
        isPackAlreadyAddedWithDuration,
        openShoppingCartSidebar,
        closeShoppingCartSidebar,
        isShoppingCartSidebarOpen: openSidebar,
        inCartNutritionalProgress,
        getPrice,
        getPriceOfPackByDuration,
        addPack,
        addPackByDuration,
        handleCloseRemoveProductModal,
        handleOpenRemoveProductModal,
        isRemoveProductModalOpen: openRemoveProductModal,
        productToRemove,
        changeAmountOfProduct,
        isProductAlreadyAddedSameAmount,
        getAmountOfProduct,
        clearShoppingCart,
        getTotalProducts,
      }}
    >
      {children}
      <ShoppingCartSidebar />
      <RemoveProductModal />
      <GradientSVG
        endColor="#0fa986"
        idCSS="gradient-svg-default"
        rotation={0}
        startColor="#007acc"
      />
    </ShoppingCartContext.Provider>
  );
};
export { ShoppingCartProvider };
