import styled from "styled-components";

export const BackButton = styled.button`
  display: flex;
  margin-right: 1rem;
`;

export const Vertical = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  > div {
    margin-top: 1rem;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > img {
    /* margin-left: 2rem; */
  }

  @media (max-width: 428px) {
    justify-content: center;
  }
`;

export const SymptomScrollButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 16px;
`;

export const AlertsContainer = styled.div`
  background-color: white;
  margin-bottom: 11px;
`;

export const Title = styled.label`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;

export const ExcipienWarpper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ExcipientCard = styled.div<{
  importance: number;
}>`
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  width: 230px;

  border: 2px solid
    ${({ importance }) =>
      importance < 2
        ? "#EDCE9F"
        : importance === 2
        ? "rgba(255, 164, 113, 0.97)"
        : "rgba(237, 159, 159, 1)"};

  > div {
    border-radius: 50%;
    padding: 1rem;
    border: 1px solid
      ${({ importance }) =>
        importance < 2
          ? "#EDCE9F"
          : importance === 2
          ? "rgba(255, 164, 113, 0.97)"
          : "rgba(237, 159, 159, 1)"};

    position: relative;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 35px;
      width: 35px;
    }
  }

  > label {
    margin-left: 10px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }
`;

export const GapDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

export const ExcipientAlertIcon = styled.div`
  > img {
    width: 100%;
    height: 100%;
  }
`;

export const HorizontalScrollContainer = styled.div`
  width: 100%;
`;

export const HealthGoalsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 6px;
  // background: ${({ theme }) => theme.colors.white};
  margin-top: 12px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;

  -webkit-box-shadow: 0px -3px 18px 1px #dbdbdb;
  box-shadow: 0px -3px 18px 1px #dbdbdb;
`;

export const CompositionTabContainer = styled.div`
  margin-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
`;
