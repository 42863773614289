import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.background};

  justify-content: center;
  align-items: center;
`;

export const Vertical = styled.div`
  width: calc(100% - 80px);
  max-width: 1440px;
  // border: 2px solid red;

  display: flex;
  flex-direction: column;

  padding-left: 16px;
  padding-right: 16px;

  margin-left: 80px;
  margin-bottom: 32px;

  @media screen and (min-width: 1714px) {
    padding-left: 146px;
    margin-left: 80px;
  }

  @media screen and (min-width: 2000px) {
    padding-left: 16px;
  }

  // @media screen and (max-width: 1680px) {
  //   padding-left: 16px;
  //   padding-right: 16px;
  // }

  // @media screen and (max-width: 1440px) {
  //   padding-left: 16px;
  //   padding-right: 16px;
  // }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: none;
    align-items: center;
  }
`;

export const Header = styled.div`
  margin: 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 46px 0 0 0;
  }
`;

export const HeaderPacks = styled.div`
  width: 100%;
  height: 65px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 18px;
  border-radius: 0 12px 0 0;
`;

export const TitleHeaderPacks = styled.h1`
  font-size: 24px;
`;

export const BasedScientificPublications = styled.div`
  width: 70%;
  height: 72px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-left: 1.5rem;
  margin: 0 6em 0 0.1rem;
`;

export const BasedScientificPublicationsMobile = styled.div`
  width: 100%;
  height: 155px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  padding: 24px;
  margin-top: 24px;
`;

export const Banner = styled.div`
  width: 100%;
  height: 531px;
  margin: 30px 0 52px 0;
  opacity: 1;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 12px;

  @media (max-width: 1366px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 428px) {
    height: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    margin: 30px 0 0 0;

    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const InfoContent = styled.div`
  width: 35%;
  height: 531px;
  background: #00000025;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding-top: 90px;
  background: linear-gradient(265.7deg, #0fa986 3.92%, #0049d6 81.28%);
  border-radius: 12px 0 0 12px;

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 330px;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ContainerTitle = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  @media (max-width: 428px) {
    margin-bottom: -20px;
  }
`;

export const ProgressCircle = styled.div`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  border: 3px solid #000;
  opacity: 0.5;

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
    margin-top: -30px;
  }
`;

export const ProgressSkeleton = styled.div`
  margin-left: 14px;
`;

export const ContainerFooterBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  @media (min-width: 1440px) {
    margin-top: 9px;
  }
`;

export const Button = styled.div`
  width: 28px;
  height: 28px;
  background: #ffffff;
  border-radius: 50%;

  @media (min-width: 1440px) {
    width: 42px;
    height: 42px;
  }
`;

export const ContainerHeaderSliderMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 30px 24px 0 24px;

  h2 {
    color: #545976;
    font-size: 18px;
    text-align: center;
  }

  span {
    font-size: 18px;
  }
`;

export const ContainerCardsTitle = styled.div`
  width: 210px;
  margin: 52px 0 24px;

  h2 {
    font-size: 18px;
    text-align: center;

    span {
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 0 12px 12px 0;
`;

export const WrapperTitleCard = styled.div`
  span {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (min-width: 1440px) {
    width: 35%;
    height: 83px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
interface CardBestProps {
  backgroundLinear: string;
}

export const CardBest = styled.div<CardBestProps>`
  width: 343px;
  height: 207px;
  background: ${({ backgroundLinear }) =>
    backgroundLinear === "green"
      ? "linear-gradient(280.83deg, #0fa986 27%, #007acc 85.55%);"
      : "linear-gradient(165.98deg, #3135C6 14.08%, #007acc 89.86%);"}
  border-radius: 12px;
  padding: 42px;

  display: flex;
  flex-direction: column;

  @media (min-width: 1440px) {
    width: 50%;
    height: 207px;
    color: #FFF;
    background: ${({ backgroundLinear }) =>
      backgroundLinear === "green"
        ? "linear-gradient(280.83deg, #0fa986 27%, #007acc 85.55%);"
        : "linear-gradient(165.98deg, #3135C6 14.08%, #007acc 89.86%);"}
    border-radius: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 42px;
    overflow: hidden;
  }

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export const CardsContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #e6e7ec;
  background: #eff2fa;

  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    overflow: hidden;
    padding: 7px;
    margin-left: 14px;
    border: none;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
`;

type TypeCardVariant = "products" | "nutrients";
interface CardBestsContainerProps {
  bgColor: TypeCardVariant;
}

const bgVariants = {
  products: "linear-gradient(280.83deg, #0fa986 27%, #007acc 85.55%)",
  nutrients: "linear-gradient(165.98deg, #3135C6 14.08%, #007acc 89.86%)",
};

export const CardBestsSkeleton = styled.div<CardBestsContainerProps>`
  width: 50%;
  height: 207px;
  color: #fff;
  background: ${({ bgColor }) => bgVariants[bgColor]};
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 42px;
  overflow: hidden;
`;
