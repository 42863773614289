import React, { useContext } from "react";
import { GlobalContext } from "../../context/global";
import { SidebarContext } from "../../context/sidebar";
import { useViewport } from "../../hooks/Viewport/useViewport";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import { Sidebar } from "../Sidebar/NavSidebar";
import { Container, Content, Vertical } from "./style";

interface PageProps {
  screen: string
  header?: () => JSX.Element
  children: React.ReactNode
  hideHeader?: boolean
  logo?: JSX.Element
  onClick?: () => void
  shouldRenderMenuIcon?: boolean
  removePadding?: boolean
  validateRowPage?: boolean
  noSidebar?: boolean,
  validateProductListPage?: boolean
}

const Page = ({
  screen,
  children,
  header,
  hideHeader = false,
  logo,
  onClick,
  shouldRenderMenuIcon = true,
  removePadding = false,
  validateRowPage,
  noSidebar,
  validateProductListPage,
}: PageProps) => {
  const { isUnderTablet } = useViewport();
  const { sidebarOpen } = useContext(SidebarContext);
  const { amountOfStudies } = useContext(GlobalContext);

  return (
    <Container>
      {isUnderTablet ? (
        <Vertical isUnderTablet={isUnderTablet} sidebarOpen={sidebarOpen}>
          {noSidebar ? <></> : (
            <HeaderMobile
              shouldRenderMenuIcon={shouldRenderMenuIcon}
              headerChildren={header}
              logo={logo}
            />
          )
          }

          <Content validateProductListPage={validateProductListPage} validateRowPage={validateRowPage} removePadding={removePadding}>{children}</Content>
        </Vertical>
      ) : (
        <>
          {noSidebar ? <></> : <Sidebar screen={screen} />}
          <Vertical
            isUnderTablet={isUnderTablet}
            sidebarOpen={sidebarOpen}
            noSidebar={noSidebar}
          >
            {!hideHeader && <Header headerChildren={header} />}
            <Content validateRowPage={validateRowPage}>{children}</Content>
          </Vertical>
        </>
      )}
    </Container>
  );
};

export default Page;
