import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../../../components/Cards/Nutrient/style";

interface ContainerProps {
  elements?: number;
}

const iconBox = css`
  height: 24px;
  width: 24px;
  // margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 13.5px;
    width: 13.5px;
  }
`;

const cardLabels = css`
  > span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.black};
    > span {
      font-size: 15px;
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.selected};
      font-weight: bold;
    }
  }

  > label {
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    width: 250px;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-width: 300px;
  /* height: 418px; */
  padding: 12px;
  /* margin: 8px; */
  max-width: 420px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const Product = styled.img`
  height: 100px;
  width: 50px;
  image-rendering: auto;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
`;

export const InfosProductContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    margin-right: 10px;
  }
`;

export const TitleContainer = styled.div`
  /* display: flex; */
`;

export const ScrollContainer = styled.div`
  height: 350px;
  width: auto;
  > .scroll-horizontal {
    overflow-x: scroll !important;
  }
`;

export const Header = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  // padding-top: 4px;
  // padding-bottom: 4px;
  width: 100%;
  /* flex-flow: wrap; */
  position: relative;

  > img {
    max-width: 100px;
  }
`;

export const CapsuleContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  height: 24px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 3px;
  padding: 12px 8px 12px 8px;
  > svg {
    height: 16px;
    width: 16px;
    // border: 2px solid red;
  }
  > label {
    margin-top: 2px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const AlertContainer = styled.div.attrs(
  (props: { isInPack: boolean }) => props
)`
  ${iconBox}
  ${({ theme }) => theme.redGradient} /* ${(props) =>
    props.isInPack && `margin-top: 4px;`} */
`;

export const ProductInfo = styled.div`
  display: flex;
  gap: 6px;
  /* flex-direction: column; */
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
`;

export const BrandName = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
`;

export const ProductName = styled.label`
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 6px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  /* height: 38px; */
  width: 250px;
  display: flex;
  justify-content: center;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ProductCompability = styled.span<{ value: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: ${({ theme }) => theme.colors.black};
  > span {
    margin-left: 6px;
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    font-weight: bold;
  }
`;

export const HealthGoalAndSymptomTitle = styled.label`
  //   border: 2px solid green;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const HealthGoalAndSymptomContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  :not(:first-child) {
    margin-top: 16px;
  }

  > div {
    justify-content: center;
    display: flex;
    flex-direction: column;

    > span {
      margin-top: 6px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 12px;

      color: ${({ theme }) => theme.colors.gray};

      opacity: 0.6;
    }
  }
`;

export const HealthGoalsAndSymptomsContainer = styled.div`
  //   margin-top: 1em;
  margin-top: 12px;
  background-color: #f5f7fe;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`;

export const HealthGoalAndSymptomScoreProgress = styled.div`
  // border: 1.5px solid red;
  // > * {
  //   width: 44px;
  //   height: 44px;
  // }
  // border-radius: 50%;
`;

export const HealthGoalsAndSymptomsIcon = styled.div`
  //   border: 2px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: auto;
  height: 100%;

  > * {
    width: 20px;
    height: auto;
  }

  //   > * {
  //     border: 2px solid green;
  //   }
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    // line-height: 39px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* height: 100%; */
  width: 100%;
  padding-bottom: 4px;
  margin: 16px 0 24px;

  > div {
    width: 50%;
    display: flex;
    align-items: center;
    // border: 2px solid orange;
    > label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.selected};
      cursor: pointer;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export const AddProductButton = styled.button<{
  added: boolean;
  width?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.selected};
  padding: 4px;
  justify-content: center;
  align-items: center;
  // border: 2px solid green;
  display: flex;
  > label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    cursor: pointer;
    // border: 2px solid red;
  }

  > svg {
    // border: 2px solid red;
    margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
  }

  &:hover {
    background: ${({ added }) =>
      added
        ? ``
        : `linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%)`};
  }

  ${({ added, theme }) => added && `background: ${theme.colors.midBlue}`}
`;
