import styled from "styled-components";

interface ListProps {
  pack: string;
}

interface ItemProps {
  default?: boolean;
  selected: boolean;
}

interface Selecterops {
  selecte: boolean;
}

export const TabNewHomeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-bottom: solid 1px #e6e7ec;
`;

export const Nav = styled.nav`
  width: 100%;
  /* margin: 0 auto; */
  padding: 3px 0;
  margin-top: 41px;
  /* border-bottom: solid 1px #E6E7EC; */

  @media (max-width: 428px) {
    margin-top: 28px;
  }
`;

export const List = styled.ul<ListProps>`
  width: 100%;
  height: 90px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 61px; */
  position: relative;
  /* border-bottom: solid 1px lightgray; */

  &::after {
    content: " ";
    width: 70px;
    /* width: ${({ pack }) =>
      pack === "essential"
        ? "70px"
        : pack === "optimal"
        ? "70px"
        : pack === "premium"
        ? "70px"
        : "0px"}; */
    
    height: 3px;
    background-color: #0CA986;
    position: absolute;
    bottom: -5px;
    left: ${({ pack }) =>
      pack === "essential"
        ? "3%;"
        : pack === "optimal"
        ? "39%;"
        : pack === "premium"
        ? "76%;"
        : "0%;"}
  
    transition: 0.2s ease-in-out;
  }

  & a:not(:first-child) li svg {
    margin: 5px;
  }
`;

export const Button = styled.a<Selecterops>`
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    ${({ selecte }) => (!selecte ? "filter: brightness(0.5);" : "")}
  }
`;

export const Item = styled.li<ItemProps>`
  width: 90px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => (props.selected ? "#0CA986" : "#545976")};
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  svg {
    width: 24px;
  }

  svg path {
    fill: ${(props) => (props.selected ? "#0CA986" : "#545976")};
  }

  /* &:target {
    transition: 0.4s ease-in-out;
    color: #0CA986;

  svg path{
    fill: #0CA986;
    transition: 0.4s ease-in-out;
  }
  } */
`;

export const SeeAllLink = styled.a`
  width: 100px;
  font-size: 18px;
  text-align: right;
  padding: 10px 0 10px 10px;
  color: ${({ theme }) => theme.colors.selected};
  transition: 0.3s;

  &:hover {
    filter: brightness(0.8);
  }
`;
