import React, { useContext, useEffect, useState } from "react";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";

import {
  BottomContent,
  CompatibilityLabel,
  Container,
  ImageContainer,
  PriceLabel,
  ProductImage,
  ProductInfo,
  ProductName,
  TopContent,
} from "./style";

import { useTranslation } from "react-i18next";
import {
  ShoppingCartContext,
  ShoppingCartItem,
} from "../../../../context/shoppingCart";
import { TrashButton } from "../../../../components/Button/ThrashButton";
import { MoreOrLessInput } from "../../../../components/Input/MoreOrLess";
import { CircleNumber } from "../CircleNumber";
import { ProductProps } from "../../../../api/types";
// interface ShoppingCartItemCardProps {
//   shoppingCartItem: ShoppingCartItem
// }
interface ProductFakeProps {
  img: string
  brand: string
  name: string
  price: string
  amount: number;
}
interface ShoppingCartItemCardProps {
  shoppingCartItem: ProductFakeProps
}

const ShoppingCartItemCard: React.FC<ShoppingCartItemCardProps> = ({
  shoppingCartItem,
}) => {
// const ShoppingCartItemCard: React.FC<ProductFakeProps> = ({
//   img, brand, name, price, amount
// }) => {
  // const { amount, product } = shoppingCartItem;
  // const { imageUrl, name, score, currency, price, brand } = product;
  const { removeProduct, changeAmountOfProduct } =
    useContext(ShoppingCartContext);
  const { t } = useTranslation();

  const [cartAmount, setCartAmount] = useState(shoppingCartItem.amount);

  const handleOnClickMore = () => {
    setCartAmount(cartAmount + 1);
  };

  const handleOnClickLess = () => {
    if (cartAmount > 1) {
      setCartAmount(cartAmount - 1);
    }
  };

  const handleOnChangeInput = (e: any) => {
    setCartAmount(e.target.value);
  };

  // const handleButtonClick = () => {
  //   removeProduct(product, 9999);
  // };

  // useEffect(() => {
  //   changeAmountOfProduct(cartAmount, product);
  // }, [cartAmount]);

  // useEffect(() => {
  //   setCartAmount(amount);
  // }, [amount]);

  return (
    <Container>
      <TopContent>
        <ImageContainer>
          <ProductImage
            src={shoppingCartItem.img}
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
          <span style={{ position: 'absolute', top: -20, left: -10 }}>
            {shoppingCartItem.amount > 1 && <CircleNumber number={shoppingCartItem.amount} />}
          </span>
        </ImageContainer>
        <ProductInfo>
          <span>{shoppingCartItem.brand}</span>
          <ProductName>{shoppingCartItem.name}</ProductName>
          {/* <CompatibilityLabel value={Math.round(score)}>
            {t("components.shoppingCartSidebar.your_compatibility")}{" "}
            <label>{`${Math.round(score)}/100`}</label>
          </CompatibilityLabel> */}
          <PriceLabel>{shoppingCartItem.price}</PriceLabel>
        </ProductInfo>
      </TopContent>
      <BottomContent>
        {/* <MoreOrLessInput
          actualValue={cartAmount}
          onClickLess={handleOnClickLess}
          onClickMore={handleOnClickMore}
          onChangeInput={handleOnChangeInput}
        /> */}
        {/* <PriceLabel>{`${getCurrencyFormat(
          currency,
          price * cartAmount
        )}`}</PriceLabel> */}
        {/* <TrashButton onClick={handleButtonClick} /> */}
      </BottomContent>
    </Container>
  );
};

export { ShoppingCartItemCard };
