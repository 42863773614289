import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ShoppingCartContext } from "../../../../context/shoppingCart";
import { useViewport } from "../../../../hooks/Viewport/useViewport";
import { CircleNumber } from "../CircleNumber";
import { ShoppingCartItemCard } from "../ShoppingCartItemCard";

import { ProductProps } from "../../../../api/types";
import { GlobalContext } from "../../../../context/global";
import {
  BackDrop,
  BasketTitle,
  CardsContainer,
  CloseAndTitle,
  Container,
  Content,
  Footer,
  Header,
  QRcodePriceContainer,
  QRcodePriceLabel,
  QRcodeTotalLabel,
} from "./style";

type ProductFakeProps = {
  product: ProductProps;
  amount: number;
};

// ? Que nome é esse se não se trata de uma sidebar?
const ShoppingCartSidebar: React.FC = () => {
  const { products } = useContext(GlobalContext);

  const fake = [
    {
      id: 1,
      img: "https://cdn-base.reload.co/nutripoint/farmacy_product/processed_images/3fea9817-6c65-4c55-8bae-292761fff332.jpeg",
      brand: "Metagenics",
      name: "Health Reserve 2000",
      price: "39.98€",
      amount: 2,
    },
    {
      id: 2,
      img: "https://cdn-base.reload.co/nutripoint/farmacy_product/processed_images/95676db9-ec35-4dcb-89a2-698c3877bf92.jpeg",
      brand: "Be Life",
      name: "Be-Life Actichrome",
      price: "114.40€",
      amount: 1,
    },
    {
      id: 3,
      img: "https://cdn-base.reload.co/nutripoint/farmacy_product/processed_images/0c57d295-76ee-4aff-9c58-3f829ba03b05.jpg",
      brand: "Nutergia",
      name: "Vectipass",
      price: "16.99€",
      amount: 3,
    },
    {
      id: 4,
      img: "https://cdn-base.reload.co/nutripoint/farmacy_product/processed_images/330d2d27-40b2-4fe1-9ab8-2d94a0b97432.jpeg",
      brand: "Be Life",
      name: "Be-Life Mg K",
      price: "54€",
      amount: 1,
    },
  ];

  // const totalPricesFake = fake.map(product => product.product.price)

  // const totalFake = totalPricesFake.reduce(function (soma, i) {
  //   return soma + i;
  // })

  const {
    isShoppingCartSidebarOpen,
    closeShoppingCartSidebar,
    inCartNutritionalProgress,
    shoppingCart,
    getPrice,
    clearShoppingCart,
  } = useContext(ShoppingCartContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUnderTablet } = useViewport();

  const validateBasket = () => {
    closeShoppingCartSidebar();

    navigate("validate-basket");
  };

  const handleButtonClick = () => {
    clearShoppingCart();
    closeShoppingCartSidebar();
    // setTimeout(() => closeShoppingCartSidebar(), 550);
  };

  useEffect(() => {
    if (isShoppingCartSidebarOpen) {
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.classList.add("overflow-auto");
    }
  }, [isShoppingCartSidebarOpen]);

  return (
    <>
      <Container open={isShoppingCartSidebarOpen}>
        <Content>
          <Header>
            <CloseAndTitle>
              {/* <CloseIcon
                size={34}
                fill={"#545976"}
                onClick={closeShoppingCartSidebar}
                className="icon-close"
              /> */}
              <BasketTitle>
                Liste de produits
                {/* {t("components.shoppingCartSidebar.product_list")} */}
                {/* Liste de produits */}
              </BasketTitle>
              {/* <CircleNumber number={shoppingCart.length} /> */}
              <CircleNumber number={7} />
            </CloseAndTitle>
            {/* <TrashButton
              onClick={handleButtonClick}
              label={t("components.shoppingCartSidebar.remove_all")}
              disabled={shoppingCart.length === 0}
            /> */}
          </Header>
          {/* <NutritionalNeeds>
            <UserIcon />
            <NutritionalNeedsContent>
              <NutritionalNeedsTitle>
                <label>{`${inCartNutritionalProgress}%`}</label>
                {t("components.shoppingCartSidebar.of_your_nutritional_needs")}
              </NutritionalNeedsTitle>
              <ProgressBar progress={inCartNutritionalProgress} />
            </NutritionalNeedsContent>
          </NutritionalNeeds> */}
          <CardsContainer>
            {/* {shoppingCart.map((item, key) => (
              <ShoppingCartItemCard shoppingCartItem={item} key={key} />
            ))} */}

            <ShoppingCartItemCard shoppingCartItem={fake[0]} key={fake[0].id} />

            <ShoppingCartItemCard shoppingCartItem={fake[1]} key={fake[1].id} />

            <ShoppingCartItemCard shoppingCartItem={fake[2]} key={fake[2].id} />

            <ShoppingCartItemCard shoppingCartItem={fake[3]} key={fake[3].id} />
          </CardsContainer>

          <Footer>
            {/* <QRcode /> */}

            <QRcodePriceContainer>
              <QRcodeTotalLabel>
                {t("components.shoppingCartSidebar.total")}
              </QRcodeTotalLabel>

              <QRcodePriceLabel>
                225.37€
                {/* {getCurrencyFormat(
                  totalFake > 0
                    ? products[0].currency
                    : "",
                  totalFake
                )} */}
              </QRcodePriceLabel>

              {/* <QRcodePriceLabel>
                {getCurrencyFormat(
                  shoppingCart.length > 0
                    ? shoppingCart[0].product.currency
                    : "",
                  getPrice()
                )}
              </QRcodePriceLabel> */}
            </QRcodePriceContainer>

            {/* <PriceContainer>
              <TotalLabel>
                {t("components.shoppingCartSidebar.total")}
              </TotalLabel>
              <PriceLabel>
                {getCurrencyFormat(
                  shoppingCart.length > 0
                    ? shoppingCart[0].product.currency
                    : "",
                  getPrice()
                )}
              </PriceLabel>
            </PriceContainer>
            <SeparateLine />
            <PrimaryButton
              label={t("components.shoppingCartSidebar.validate")}
              onClick={validateBasket}
              width={"194px"}
              disabled={shoppingCart.length === 0}
            /> */}
          </Footer>
        </Content>
      </Container>
      <BackDrop
        open={isShoppingCartSidebarOpen}
        onClick={closeShoppingCartSidebar}
      />
    </>
  );
};

export { ShoppingCartSidebar };
