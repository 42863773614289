import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

const iconBox = css`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const CardContainer = styled.div<{ horizontal?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  align-items: center;
  margin-bottom: 1rem;

  // border: 2px solid red;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  margin-left: 50px;

  > img {
    // min-height: 100px;
    height: 205px;
    width: auto;
  }

  // border: 2px solid red;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin-left: 1rem;
  // border: 2px solid green;
`;

export const BrandName = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  // border: 2px solid orange;
`;

export const NutrientName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 8px;
  > label {
    font-weight: 400;
  }
`;

export const Compatibility = styled.span<{ value: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 22px;

  > span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    margin-left: 6px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const ProductIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  // border: 2px solid red;
  justify-content: center;
  align-items: center;
  margin-right: 42px;
  gap: 8px;
`;

export const CardHeader = styled.div`
  width: 100%;
  //   padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

interface StarContainerProps {
  isTheMostRecommended?: boolean;
}

export const StarContainer = styled.div<StarContainerProps>`
  ${iconBox}
  ${({ theme, isTheMostRecommended }) => isTheMostRecommended ? theme.goldGradient : theme.blueGradient}
  /* ${({ theme }) => theme.blueGradient} */
`;
