import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bgHeaderMobile from "../../assets/bg-header-mobile.png";
import bgHeader from "../../assets/bg-header.png";
import logoMobile from "../../assets/logo-mobile.svg";
import Page from "../../components/Page";
import { GlobalContext } from "../../context/global";
import { useViewport } from "../../hooks/Viewport/useViewport";
import nutrientsCapsules from "./assets/nutrients-capsules.svg";
import productBottle from "./assets/products-bottle.png";
import { CardBests } from "./components/CardBests";
import { SliderPacks } from "./components/SliderPacks";
import { SliderPacksMobile } from "./components/SliderPacksMobile";
import { TabNewHome } from "./components/TabNewHome";
import { TabNewHomeMobile } from "./components/TabNewHomeMobile";
import {
  ContainerCardsSection,
  ContainerUserNameTitle,
  HeaderContainer,
  HeaderLabel,
  ScientificPublications,
  TitileSectionCards,
  UserName,
  WrapperCardsBests,
} from "./style";

export function Home() {

  const [selectedPack, setSelectedPack] = useState("essential");
  const { products, nutrients, amountOfStudies, userName, userGender } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const { isUnderTablet, isUnderIpad } = useViewport();
  const { t } = useTranslation();

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  const RenderHeaderLogo = () => (
    <HeaderContainer>
      <img src={logoMobile} alt="" />
    </HeaderContainer>
  );

  function handleSelectedPack(packName: string) {
    setSelectedPack(packName);
  }

  const 
  RenderHeader = () => {
    return (
      <HeaderContainer>
        <UserName>
          {
            userGender === "male" ?
              t("screens.home.hello_male", {
                name: userName,
              })
              : t("screens.home.hello_female", {
                name: userName,
              })
          }
        </UserName>
        <HeaderLabel>
          <ScientificPublications>
            <h3>
              {t("screens.home.title")}{" "}
              <span>
                {t("screens.home.based_on", {
                  count: amountOfStudies,
                })}
              </span>{" "}
            </h3>
          </ScientificPublications>
          <img src={bgHeader} alt=""/>
        </HeaderLabel>
      </HeaderContainer>
    );
  };

  return (
    <>
      {isUnderTablet ? (
        <Page screen="home" logo={RenderHeaderLogo()}>
          <ContainerUserNameTitle>
            <UserName onClick={() => navigate("/validate-product-list")}>
              {
                userGender === "male" ?
                  t("screens.home.hello_male", {
                    name: userName,
                  })
                  : t("screens.home.hello_female", {
                    name: userName,
                  })
              }
            </UserName>
            <HeaderLabel>
              <ScientificPublications>
                <h3>
                  {t("screens.home.title")}{" "}
                  <span>
                    {t("screens.home.based_on", {
                      count: amountOfStudies,
                    })}
                  </span>{" "}
                </h3>
              </ScientificPublications>
              <img src={bgHeaderMobile} alt="" />
            </HeaderLabel>
          </ContainerUserNameTitle>

          <TabNewHomeMobile
            onSelectedPack={handleSelectedPack}
            selectedPack={selectedPack}
          />

          <SliderPacksMobile selectedPack={selectedPack} gender={userGender} />

          <ContainerCardsSection>
            <TitileSectionCards
              dangerouslySetInnerHTML={{
                __html: t("screens.home.see_other_possibilities"),
              }}
            ></TitileSectionCards>

            <WrapperCardsBests>
              <CardBests
                title={t("screens.home.best_products")}
                subTitle={t(
                  "screens.home.tabs.bestProducts.based_of_products",
                  {
                    count: products.length,
                  }
                )}
                typeCard="products"
                imgCard={<img src={productBottle} alt="products bottle" />}
                imgMargin={8}
                onLinkNAvigate={() => navigate("/products")}
              />
              <CardBests
                title={t("screens.home.best_nutrients")}
                subTitle={t(
                  "screens.home.tabs.bestNutrients.based_of_nutrients",
                  {
                    count: nutrients.length,
                  }
                )}
                typeCard="nutrients"
                imgCard={
                  <img src={nutrientsCapsules} alt="nutrients capsules" />
                }
                onLinkNAvigate={() => navigate("/nutritional-needs")}
              />
            </WrapperCardsBests>
          </ContainerCardsSection>
        </Page>
      ) : (
        <Page screen="home" header={RenderHeader}>
          <TabNewHome
            onSelectedPack={handleSelectedPack}
            selectedPack={selectedPack}
          />

          <SliderPacks selectedPack={selectedPack}  gender={userGender}/>

          <ContainerCardsSection>
            {!isUnderIpad && (
              <TitileSectionCards
                dangerouslySetInnerHTML={{
                  __html: t("screens.home.see_other_possibilities"),
                }}
              ></TitileSectionCards>
            )}

            <WrapperCardsBests>
              <CardBests
                title={t("screens.home.best_products")}
                subTitle={t(
                  "screens.home.tabs.bestProducts.based_of_products",
                  {
                    count: products.length,
                  }
                )}
                typeCard="products"
                imgCard={<img src={productBottle} alt="products bottle" />}
                onLinkNAvigate={() => navigate("/products")}
              />
              <CardBests
                title={t("screens.home.best_nutrients")}
                subTitle={t(
                  "screens.home.tabs.bestNutrients.based_of_nutrients",
                  {
                    count: nutrients.length,
                  }
                )}
                typeCard="nutrients"
                imgCard={
                  <img src={nutrientsCapsules} alt="nutrients capsules" />
                }
                onLinkNAvigate={() => navigate("/nutritional-needs")}
              />
            </WrapperCardsBests>
          </ContainerCardsSection>
        </Page>
      )}
    </>
  );
}
