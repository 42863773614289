import styled from "styled-components";

export const HeaderMobileContainer = styled.header<{
  scrollDirection: "up" | "down" | null;
}>`
  width: 100%;
  height: 64px;
  background: linear-gradient(180deg, #3135c6 15%, #007acc 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: sticky;
  top: 0px;

  ${({ scrollDirection }) =>
    scrollDirection === "down" &&
    `
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  `}
  ${({ scrollDirection }) =>
    scrollDirection === "up" &&
    `
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  `}


  z-index: 995;

  padding: 0 1rem;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Commom = styled.div`
  /* justify-content: space-between; */
  align-items: center;
  /* width: 20%; */
  display: flex;
  gap: 66px;

  @media screen and (max-width: 375px) {
    gap: 20px;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 25px;
    width: 25px;
  }
`;
