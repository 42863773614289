import styled from "styled-components"


export const Container = styled.span`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* ${({theme}) => theme.orangeGradient}; */
  background: linear-gradient(180deg, #00A984 0%, #0AE2D5 100%);
  color: #fff;
  font-size: 18px;
`;