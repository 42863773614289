import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  // height: auto; // TODO deveria ser 100% mas quebra muita coisa
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  @media screen and (min-width: 1714px) {
    justify-content: center;
  }
`;

interface VerticalProps {
  isUnderTablet: boolean;
  sidebarOpen: boolean;
  noSidebar?: boolean
}

export const Vertical = styled.div<VerticalProps>`
  flex-direction: column;
  height: 100%;
  width: ${(props) =>
    props.isUnderTablet
      ? "100%"
      : props.sidebarOpen
      ? "calc(100% - 274px)"
      : "calc(100% - 80px)"};
  max-width: 1440px;
  margin-left: ${({ sidebarOpen }) => (sidebarOpen ? "274px" : "80px")};
  margin-left: ${({ isUnderTablet }) => isUnderTablet && "0"};
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  transition: all 500ms;

  ${({ noSidebar }) => noSidebar && `
    margin-left: 16px;
    width: 100%;
  `}

  @media screen and (min-width: 1714px) {
    padding-left: 130px;
    margin-left: 80px;
  }

  @media screen and (min-width: 2000px) {
    padding-left: 0px;
  }

  > h2 {
    width: 90%;
    font-size: 1.125rem;
    margin: 1rem 0 1.6875rem;

    color: ${({ theme }) => theme.colors.gray};

    span {
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }
`;

export const Content = styled.main<{ removePadding?: boolean, validateRowPage?: boolean, validateProductListPage?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 1rem 2rem;

  ${({ removePadding }) =>
    removePadding &&
    `
    padding: 0;
  `}
  /* padding: 1rem 2rem; */
  display: flex;
  flex-direction: column;

  ${({ validateRowPage }) => validateRowPage && `
    flex-direction: row;
    height: 100vh;
  `}

${({ validateProductListPage }) => validateProductListPage && `
    // height: 100vh;
  `}
`;
