import styled from "styled-components";

export const Container = styled.div<{ warn?: boolean; clickable: boolean }>`
  width: 148px;
  min-width: 148px;
  height: 169px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  background-color: white;

  ${({ theme }) => theme.lightShadow}
  // margin-right: 8px;
  ${({ clickable }) => clickable && `cursor: pointer;`}

  > div {
    display: flex;
    :first-child {
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background: ${({ warn }) =>
        warn
          ? "linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%)"
          : "linear-gradient(165.98deg, #3135C6 14.08%, #007acc 89.86%)"};
    }

    :last-child {
      height: 40%;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      > label {
        color: ${({ theme }) => theme.colors.black};
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
`;

export const Icon = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopLeftIcon = styled.div`
  position: absolute;
  left: 6px;
  top: 6px;

  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const TopRightIcon = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;

  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 80%;
`;

export const IconContainer = styled.div`
  > svg {
    > defs {
      // border: 2px solid red;
    }
  }
`;
