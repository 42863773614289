import { useTranslation } from "react-i18next";
import { AgentFieldOfActionType } from "../../../../../api/types";
import { ReactComponent as AlertIcon } from "../../../../../assets/icons/svg/alertOrangeIcon.svg";
import { ReactComponent as SynergyIcon } from "../../../../../assets/icons/svg/synergyBlueIcon.svg";
import RoundedProgress from "../../../../../components/RoundedProgress";
import { getSymptomsIcon } from "../../../../../utils/getSymptomsIcon";
import { Field, FieldName, ProgressContainer } from "./style";

type FieldOfActionCardProps = {
  field: AgentFieldOfActionType;
  componentId: number;
  componentName: string;
};

const FieldOfActionCard = ({
  field,
  componentId,
  componentName,
}: FieldOfActionCardProps) => {
  const { t } = useTranslation();

  const color = field.isContraindication
    ? { color1: "#ED9F9F", color2: " #FFA471" }
    : { color1: "#0fa986", color2: " #007acc" };

  // TODO colocar para pegar TODOS as sinergias, tal qual é feito com drugInteractions
  const getSynergyPhrase = () => {
    if (!field.hasSynergy) {
      return "";
    }

    const { phrase, activeAgentName, passiveAgentName } = field.synergies[0];

    return phrase
      .replace(activeAgentName, `<strong>${activeAgentName}</strong>`)
      .replace(passiveAgentName, `<strong>${passiveAgentName}</strong>`);
  };

  return (
    <Field
      id={`${componentId}-${field.name}`}
      border={
        field.isContraindication
          ? "pink"
          : field.hasSynergy
          ? "gradient"
          : "none"
      }
    >
      <div>
        <RoundedProgress
          grayForeground
          progress={field.score}
          size={46}
          linear={color}
          thickness={6}
        >
          <ProgressContainer
            border={field.isContraindication ? "pink" : "gradient"}
          >
            {getSymptomsIcon(field.categoryId)}
          </ProgressContainer>
        </RoundedProgress>
      </div>
      <FieldName nameSize={field.name.length}>{field.name}</FieldName>
      {field.hasSynergy && (
        <>
          <SynergyIcon />
        </>
      )}
      {field.isContraindication && (
        <>
          <AlertIcon />
        </>
      )}
    </Field>
  );
};

export default FieldOfActionCard;
