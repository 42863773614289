import styled from "styled-components";

type TypeCardVariant = "products" | "nutrients";

interface CardBestsContainerProps {
  bgColor: TypeCardVariant;
}

const bgVariants = {
  products: "linear-gradient(280.83deg, #0fa986 27%, #007acc 85.55%)",
  nutrients: "linear-gradient(165.98deg, #3135C6 14.08%, #007acc 89.86%)",
};

export const CardBestsContainer = styled.div<CardBestsContainerProps>`
  width: 50%;
  height: 207px;
  color: #fff;
  background: ${({ bgColor }) => bgVariants[bgColor]};
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 42px;

  @media (max-width: 428px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 24px;

    overflow: hidden;
  }
`;

export const ContainerTitleMobile = styled.div`
  width: 100%;
  height: 73px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface ContainerButtonMobileProps {
  imgMargin?: number;
}

export const ContainerButtonMobile = styled.div<ContainerButtonMobileProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > img {
    width: 120px;
    margin-top: ${({ imgMargin }) => (imgMargin ? `-${imgMargin}px` : "0px")};
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 32px;
`;

export const SubTitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05rem;
  margin-bottom: 8px;

  span {
    font-weight: 700;
  }
`;

export const Button = styled.button`
  width: 89px;
  height: 29px;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 700;
  letter-spacing: 0.02rem;

  background-color: #fff;
  border-radius: 29px;
  transition: 0.2s;

  &:hover {
    filter: opacity(0.8);
  }
`;
