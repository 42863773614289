import { useContext, useEffect, useState } from "react";
import { ReactComponent as AnimationMobile1 } from "../../assets/animation/validateBasket/checkout_animation.svg";
import { ReactComponent as Amazon } from "../../assets/animation/validateBasket/amazon.svg";
import {
  ReactComponent as Kpsule,
  ReactComponent as MediMarket,
} from "../../assets/pharmacy-logos/pharmacy-13-medi-market.svg";

import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { GlobalContext } from "../../context/global";
import { SetupAnswerContext } from "../../context/setupAnswer";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { useViewport } from "../../hooks/Viewport/useViewport";
import { ShoppingCartSidebar } from "./components/ShoppingCartSidebar";
import { ContainerBottom, ContainerTop, Title } from "./style";

const ValidateProductList = () => {
  const { t, i18n } = useTranslation();
  const { pharmacyId } = useContext(SetupAnswerContext);
  const { userName } = useContext(GlobalContext);
  const renderPartnerLogo = () => {

    if (pharmacyId == 5) {
      return <MediMarket className="amazon" />;
    } else {
      return <Amazon className="amazon" />;
    }
  };

  useEffect(() => {
    i18n.changeLanguage("fr");
  }, []);

  return (
    <Page
      screen="validate-basket"
      hideHeader
      noSidebar
      removePadding
      validateProductListPage
    >
      <ContainerTop showAnimation1={true}>
        <Kpsule className="kpsule" />
        <Title>
          <span>Anthony</span>, {t("screens.validate.product_list")}
        </Title>
        <AnimationMobile1 className="animation-1" />
      </ContainerTop>
      <ContainerBottom>
        <ShoppingCartSidebar />
      </ContainerBottom>
    </Page>
  );
};

export default ValidateProductList;
