import styled from "styled-components";
import scrollbar from "../../../../styles/scrollbar";

export const ContainerSlider = styled.div`
  width: 100%;
  height: 531px;
  margin-top: 30px;
  background-color: #eff2fa;
  /* border: 1px solid #E6E7EC; */
  border-radius: 12px;
  display: flex;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  @media (max-width: 1366px) {
    margin-top: 12px;
  }
`;

interface BannerProps {
  img: string;
}

export const BannerWrapper = styled.div<BannerProps>`
  width: 35%;
  /* background-image: linear-gradient(57.75deg, rgba(49, 54, 160, 1), rgba(44, 208, 145, 1)), ${({
    img,
  }) => img && `url(${img})`}; */
  background: ${({ img }) => img && `url(${img}) no-repeat center`};
  /* background: linear-gradient(57.75deg, #3136A0 15.85%, #2CD091 80.62%); */
  background-size: cover;
  background-repeat: no-repeat;
  /* mix-blend-mode: multiply; */
  /* background-repeat: no-repeat; */
  /* mix-blend-mode: normal; */
  border-radius: 12px 0 0 12px;
  overflow: hidden;
  transition: all 0.5s;
  position: relative;

  .water-mark {
    position: absolute;
  }
`;

export const BackgroundGradientBanner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(57.75deg, #3136a0 15.85%, #2cd091 80.62%);
  /* opacity: 0.9; */
  mix-blend-mode: multiply;
`;

export const TitleBanner = styled.h1`
  width: 350px;
  font-size: 32px;
  /* font-weight: 400; */
  text-align: center;
  // line-height: 48px;
  margin: 0 auto;
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
  color: #fff;
  font-weight: 400;

  span {
    /* color: #0CA986; */
  }
`;

export const CircularProgressContainer = styled.div`
  width: 164px;
  height: 164px;

  left: 0;
  right: 0;
  margin: 0 auto;
  top: 45%;
  position: absolute;

  // border: 2px solid green;
`;

export const ContainerPackProductsLength = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  margin: 0 auto;
  bottom: 9%;
  left: 0;
  right: 0;
`;

export const PackProductCount = styled.span`
  width: 42px;
  height: 42px;
  color: #0ca986;
  background-color: #fff;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitlePackProductCount = styled.h2`
  font-size: 24px;
  color: #fff;
`;

export const ContainerCardsPacks = styled.div`
  width: 65%;
  border: 1px solid #e6e7ec;
  border-radius: 0 12px 12px 0;
`;

export const HeaderPacks = styled.div`
  height: 100px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-radius: 0 12px 0 0;
`;

export const TitleHeaderPacks = styled.h1`
  font-size: 24px;
`;

export const CardsContainer = styled.div`
  width: 100%;
  padding: 0 17px 8px;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: scroll;

  overflow: overlay;

  ${scrollbar}

  @media screen and (max-width: 1024px) {
    width: 100%;

    align-items: stretch;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 100%;
    overflow-x: scroll;

    justify-content: flex-start;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
`;

export const SeeAllLink = styled.span`
  width: 100px;
  font-size: 18px;
  text-align: right;
  padding: 10px 0 10px 10px;
  color: ${({ theme }) => theme.colors.selected};
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;
