import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  /* padding: 1.5rem 2rem 0.5rem 2rem; */
  display: flex;
  /* height: auto; */
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  // border: 2px solid red;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
`;

export const Circle = styled.div`
  width: 38px;
  height: 38px;
  margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);
  position: absolute;
  top: 50%;
  left: -50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;

export const Commom = styled.div`
  /* justify-content: space-between; */
  align-items: center;
  /* width: 20%; */
  display: flex;
  gap: 102px;
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  // border: 2px solid blue;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    // border: 2px solid red;
  }
`;

export const CartProgressContainer = styled.div`
  width: 67px;
  height: 67px;
  cursor: pointer;
  position: relative;
`;
