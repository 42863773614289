import React, { createContext, useContext, useEffect, useState } from "react";
import {
  HealthGoalFilter,
  NutrientProps,
  ProductProps,
  SymptomAvatarFilter,
  SymptomCategoryFilter,
} from "../api/types";
import { FilterSidebar } from "../components/Sidebar/FilterSidebar";
import { getIntersection } from "../utils/getIntersection";
import { GlobalContext } from "./global";

interface FilterContextInterface {
  filteredProducts: ProductProps[];
  handleToggleAvatarSymptomIndex: (index: number) => void;
  handleToggleCategorySymptomIndex: (index: number) => void;
  handleToggleHealthGoalIndex: (index: number) => void;
  avatarSymptoms: SymptomAvatarFilter[];
  categorySymptoms: SymptomCategoryFilter[];
  handleOpenSidebar: () => void;
  handleCloseSidebar: () => void;
  healthGoals: HealthGoalFilter;
  maxPrice: number;
  minPrice: number;
  handleSetMaxPriceFilter: (price: number) => void;
  handleSetMinPriceFilter: (price: number) => void;
  maxPriceFilter: number;
  minPriceFilter: number;
  applyFilter: () => void;
  clearFilters: () => void;
  avatarSymptomsIndexSelected: number[];
  setAvatarSymptomsIndexsSelected: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  categorySymptomsIndexSelected: number[];
  setCategorySymptomsIndexSelected: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  healthGoalsIndexSelected: number[];
  setHealthGoalsIndexSelected: React.Dispatch<React.SetStateAction<number[]>>;
  filteredNutrients: NutrientProps[];
  loadingFilter: boolean;
  nutrientsSearchInput: string;
  setNutrientsSearchInput: React.Dispatch<React.SetStateAction<string>>;
  productsSearchInput: string;
  setProductsSearchInput: React.Dispatch<React.SetStateAction<string>>;
  minVal: number;
  setMinVal: React.Dispatch<React.SetStateAction<number>>;
  maxVal: number;
  setMaxVal: React.Dispatch<React.SetStateAction<number>>;
}

export const FilterContext = createContext({} as FilterContextInterface);

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    filters,
    products,
    defaultCurrency,
    nutrients,
    highestPrice,
    lowestPrice,
  } = useContext(GlobalContext);
  const [openSidebar, setOpenSidebar] = useState(false);

  const [loadingFilter, setLoadingFilter] = useState(false);

  const [nutrientsSearchInput, setNutrientsSearchInput] = useState("");
  const [productsSearchInput, setProductsSearchInput] = useState("");

  const [minVal, setMinVal] = useState(lowestPrice);
  const [maxVal, setMaxVal] = useState(highestPrice);

  useEffect(() => {
    setMinVal(lowestPrice);
    setMaxVal(highestPrice);
  }, [lowestPrice, highestPrice]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const [avatarSymptoms, setAvatarSymptoms] = useState<SymptomAvatarFilter[]>(
    []
  );
  const [categorySymptoms, setCategorySymptoms] = useState<
    SymptomCategoryFilter[]
  >([]);

  const [healthGoals, setHealthGoals] = useState<HealthGoalFilter>({});

  const [avatarSymptomsIndexSelected, setAvatarSymptomsIndexsSelected] =
    useState<number[]>([]);
  const [categorySymptomsIndexSelected, setCategorySymptomsIndexSelected] =
    useState<number[]>([]);

  const [healthGoalsIndexSelected, setHealthGoalsIndexSelected] = useState<
    number[]
  >([]);

  const [maxPriceFilter, setMaxPriceFilter] = useState(0);
  const [minPriceFilter, setMinPriceFilter] = useState(0);

  const handleToggleAvatarSymptomIndex = (index: number) => {
    if (avatarSymptomsIndexSelected.includes(index)) {
      setAvatarSymptomsIndexsSelected(
        avatarSymptomsIndexSelected.filter((_index) => _index !== index)
      );
    } else {
      setAvatarSymptomsIndexsSelected((prev) => [...prev, index]);
    }
  };

  const handleToggleCategorySymptomIndex = (index: number) => {
    if (categorySymptomsIndexSelected.includes(index)) {
      setCategorySymptomsIndexSelected(
        categorySymptomsIndexSelected.filter((_index) => _index !== index)
      );
    } else {
      setCategorySymptomsIndexSelected((prev) => [...prev, index]);
    }
  };

  const handleToggleHealthGoalIndex = (index: number) => {
    if (healthGoalsIndexSelected.includes(index)) {
      setHealthGoalsIndexSelected(
        healthGoalsIndexSelected.filter((_index) => _index !== index)
      );
    } else {
      setHealthGoalsIndexSelected((prev) => [...prev, index]);
    }
  };

  const [productsIdList, setProductsIdList] = useState<number[]>([]);
  const [nutrientsIdList, setNutrientsIdList] = useState<number[]>([]);

  const [filteredProducts, setFilteredProducts] = useState<ProductProps[]>([]);
  const [filteredNutrients, setFilteredNutrients] = useState<NutrientProps[]>(
    []
  );

  useEffect(() => {
    setFilteredProducts(products.filter(product => product.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(productsSearchInput.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsSearchInput]);
  

  useEffect(() => {
    setFilteredNutrients(nutrients);
  }, [nutrients]);

  const applyFilter = () => {
    setLoadingFilter(true);
  };

  useEffect(() => {
    if (!loadingFilter) {
      applyFilterAfterLoading();
    } else {
      setTimeout(() => setLoadingFilter(false), 200);
    }
  }, [loadingFilter]);

  useEffect(() => {
    applyFilter();
  }, [productsIdList]);

  const applyFilterAfterLoading = () => {
    if (productsIdList.length > 0) {
      if (maxPriceFilter !== highestPrice || minPriceFilter !== lowestPrice) {
        setFilteredProducts(
          products
            .filter((product) => productsIdList.includes(product.id))
            .filter(
              (product) =>
                product.price >= minPriceFilter &&
                product.price <= maxPriceFilter
            )
        );

        setFilteredNutrients(
          nutrients.filter((nutrient) => nutrientsIdList.includes(nutrient.id))
        );
      } else {
        setFilteredProducts(
          products.filter((product) => productsIdList.includes(product.id))
        );

        setFilteredNutrients(
          nutrients.filter((nutrient) => nutrientsIdList.includes(nutrient.id))
        );
      }
    } else {
      if (maxPriceFilter !== highestPrice || minPriceFilter !== lowestPrice) {
        setFilteredProducts(
          products.filter(
            (product) =>
              product.price >= minPriceFilter && product.price <= maxPriceFilter
          )
        );

        setFilteredNutrients(nutrients);
      } else {
        setFilteredProducts(products);
        setFilteredNutrients(nutrients);
      }
    }
  };

  var clearFilterEvent = new CustomEvent("clearFilters");

  const clearFilters = () => {
    setProductsIdList([]);
    setHealthGoalsIndexSelected([]);
    setCategorySymptomsIndexSelected([]);
    setAvatarSymptomsIndexsSelected([]);
    setNutrientsSearchInput("");
    setProductsSearchInput("");

    setMaxPriceFilter(highestPrice);
    setMinPriceFilter(lowestPrice);
    setFilteredProducts(products);
    setFilteredNutrients(nutrients);

    document.dispatchEvent(clearFilterEvent);
  };

  useEffect(() => {
    setAvatarSymptoms(filters.symptomsAvatar);
    setCategorySymptoms(filters.symptomsCategory);
    setHealthGoals(filters.healthGoals);
  }, [filters]);

  useEffect(() => {
    let avatarSymptomProductsIdList: number[] = [];
    let categorySymptomProductsIdList: number[] = [];
    let healthGoalsProductsIdList: number[] = [];

    let avatarSymptomNutrientsIdList: number[] = [];
    let categorySymptomNutrientsIdList: number[] = [];
    let healthGoalsNutrientsIdList: number[] = [];

    if (avatarSymptomsIndexSelected.length > 0) {
      avatarSymptomsIndexSelected.forEach((index) => {
        avatarSymptomProductsIdList = [...avatarSymptoms[index].productsId];
        avatarSymptomNutrientsIdList = [...avatarSymptoms[index].nutrientsId];
      });
    }

    if (categorySymptomsIndexSelected.length > 0) {
      categorySymptomsIndexSelected.forEach((index) => {
        categorySymptomProductsIdList = [...categorySymptoms[index].productsId];
        categorySymptomNutrientsIdList = [
          ...categorySymptoms[index].nutrientsId,
        ];
      });
    }

    if (healthGoalsIndexSelected.length > 0) {
      healthGoalsIndexSelected.forEach((index) => {
        const keys = Object.keys(healthGoals);

        const productsIdList =
          healthGoals[keys[index] as keyof HealthGoalFilter]?.productsId;

        const nutrientsIdList =
          healthGoals[keys[index] as keyof HealthGoalFilter]?.nutrientsId;

        if (productsIdList) {
          healthGoalsProductsIdList = [...productsIdList];
        }
        if (nutrientsIdList) {
          healthGoalsNutrientsIdList = [...nutrientsIdList];
        }
      });
    }

    setProductsIdList(
      getIntersection([
        avatarSymptomProductsIdList,
        categorySymptomProductsIdList,
        healthGoalsProductsIdList,
      ])
    );

    setNutrientsIdList(
      getIntersection([
        avatarSymptomNutrientsIdList,
        categorySymptomNutrientsIdList,
        healthGoalsNutrientsIdList,
      ])
    );
  }, [
    avatarSymptomsIndexSelected,
    categorySymptomsIndexSelected,
    healthGoalsIndexSelected,
  ]);

  useEffect(() => {
    if (productsIdList.length > 0) {
      if (maxPriceFilter !== highestPrice || minPriceFilter !== lowestPrice) {
        setFilteredProducts(
          products
            .filter((product) => productsIdList.includes(product.id))
            .filter(
              (product) =>
                product.price >= minPriceFilter &&
                product.price <= maxPriceFilter
            )
        );

        setFilteredNutrients(
          nutrients.filter((nutrient) => nutrientsIdList.includes(nutrient.id))
        );
      } else {
        setFilteredProducts(
          products.filter((product) => productsIdList.includes(product.id))
        );

        setFilteredNutrients(
          nutrients.filter((nutrient) => nutrientsIdList.includes(nutrient.id))
        );
      }
    } else {
      if (maxPriceFilter !== highestPrice || minPriceFilter !== lowestPrice) {
        setFilteredProducts(
          products.filter(
            (product) =>
              product.price >= minPriceFilter && product.price <= maxPriceFilter
          )
        );

        setFilteredNutrients(nutrients);
      } else {
        setFilteredProducts(products);
        setFilteredNutrients(nutrients);
      }
    }
  }, [maxPriceFilter, minPriceFilter]);

  const handleSetMinPriceFilter = (price: number) => {
    setMinPriceFilter(price);
  };

  const handleSetMaxPriceFilter = (price: number) => {
    setMaxPriceFilter(price);
  };

  return (
    <FilterContext.Provider
      value={{
        filteredProducts,
        handleToggleAvatarSymptomIndex,
        handleToggleCategorySymptomIndex,
        handleToggleHealthGoalIndex,
        avatarSymptoms,
        categorySymptoms,
        handleCloseSidebar,
        handleOpenSidebar,
        healthGoals,
        maxPrice: highestPrice,
        minPrice: lowestPrice,
        handleSetMinPriceFilter,
        handleSetMaxPriceFilter,
        maxPriceFilter,
        minPriceFilter,
        applyFilter,
        clearFilters,
        avatarSymptomsIndexSelected,
        categorySymptomsIndexSelected,
        healthGoalsIndexSelected,
        filteredNutrients,
        setAvatarSymptomsIndexsSelected,
        setCategorySymptomsIndexSelected,
        setHealthGoalsIndexSelected,
        loadingFilter,
        nutrientsSearchInput,
        setNutrientsSearchInput,
        productsSearchInput,
        setProductsSearchInput,
        maxVal,
        minVal,
        setMaxVal,
        setMinVal,
      }}
    >
      {children}
      <FilterSidebar
        onOpen={handleOpenSidebar}
        onClose={handleCloseSidebar}
        isFilterSidebarOpen={openSidebar}
      />
    </FilterContext.Provider>
  );
};
export { FilterProvider };
