import { Tab, TabList, Tabs } from "react-tabs";
import styled from "styled-components";

export const TabsContainer = styled(Tabs)`
  width: 100%;
  margin-top: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TabListContent = styled(TabList)`
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
`;

export const UnorderedTab = styled(Tab)<{ selected: boolean }>`
  font-size: 24px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.selected : theme.colors.black};
  font-weight: bold;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: color 360ms;
  padding-right: 38.5px;
  :not(:first-child) {
    padding-left: 38.5px;
    padding-right: 38.5px;
  }

  // border: 2px solid red;
`;

export const FlagContainer = styled.div`
  position: relative;
  cursor: pointer;
  // border: 2px solid green;

  > label {
    cursor: pointer;
    display: flex;
    align-items: center;

    // border: 2px solid red;
  }
`;

export const Flag = styled.div<{ show: boolean }>`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 5px;
  position: absolute;
  margin-top: 12px;
  left: 0;
  right: 0;
  transition: opacity 360ms;
  ${({ show }) => (show ? `opacity: 1` : `opacity: 0`)};

  // border: 2px solid orange;

  background-color: ${({ theme }) => theme.colors.selected};
`;

export const LearnMore = styled.span`
  width: 100px;
  height: 30px;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.selected};
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Circle = styled.div<{ value: number }>`
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);

  margin-left: 12px;

  // border: 2px solid black;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ value }) => (value < 10 ? "16px" : "14px")};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;
