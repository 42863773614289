import styled from "styled-components";

export const ContainerMobile = styled.div<{
  showAnimation1: boolean;
  showAnimation2: boolean;
}>`
  display: flex;
  flex: auto;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  padding-top: 200px;

  // border: 2px solid red;

  .kpsule {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;
  }

  .amazon {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;
    max-width: 300px;
  }

  .animation-1 {
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 0;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);

    max-height: 300px;

    // border: 2px solid red;
  }

  .animation-2 {
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 0;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);

    max-height: 300px;

    // border: 2px solid red;
  }
`;

export const Container = styled.div<{
  showAnimation1: boolean;
  // showAnimation2: boolean;
}>`
  /* width: 1200px; */
  width: calc(95% - 390px);
  display: flex;
  /* flex: auto; */
  /* height: 100vh; */
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  /* padding-top: 55px; */

  // border: 2px solid red;

  .kpsule {
    width: 200px;
    /* opacity: 1;
    transition: all 1s linear; */
  }

  .animation-1 {
    /* width: 550px; */
    width: 700px;
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;
    margin: 0 auto;
    /* position: absolute;
    top: 60%; */
    /* -ms-transform: translateY(-50%);
    transform: translateY(-50%); */

    /* max-height: 600px; */

    // border: 2px solid red;

    // @media screen and (max-width: 428px) {
    //   width: 150%;
    //   top: -250px;
    // }
  }
`;

export const Title = styled.label`
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 38px;
  line-height: 57px;
  color: ${({ theme }) => theme.colors.black};
  /* margin-top: 16px; */

  span {
    color: ${({theme}) => theme.colors.selected};
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin-top: 20px;
    /* position: absolute;
    bottom: 35%; */
    bottom: 0px;
    top: initial;
  }
`;

export const ContainerFooter = styled.div<{
  showAnimation1: boolean;
  showAnimation2: boolean;
}>`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 23px;
  align-items: center;

  margin-top: 80px;
  position: relative;

  // border: 2px solid blue;

  .kpsule {
    opacity: 1;
    transition: all 1s linear;
    // scale: 0.5;
    width: 100%;
    margin-top: 60px;

    // border: 2px solid red;
  }

  .amazon {
    position: absolute;
    top: 58%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;
    max-width: 300px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemText = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.black};

  span {
    color: ${({theme}) => theme.colors.selected}
  }
`;

export const Footeritems = styled.div`
  width: 37%; 
  display: flex;
  gap: 15px;

  @media (max-width: 1366px) {
    width: 40%; 
  }
`;