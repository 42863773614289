import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

const iconBox = css`
  height: 31px;
  width: 31px;
  margin-left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 17.5px;
    width: 17.5px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;

  > svg {
    height: 25px;
    width: 25px;
    color: ${({ theme }) => theme.colors.black};
  }
  > label {
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    margin-left: 8px;
  }
`;

export const CardContainer = styled.div<{ horizontal?: boolean }>`
  width: 100%;
  height: 100%;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  /* padding: 30px 25px; */
  /* padding-left: 58px; */
  /* padding-right: 58px; */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  justify-content: space-between;
  align-items: flex-start;
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3); */
  margin-bottom: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 85%;
  min-height: 150px;
  justify-content: center;
  align-items: center;

  > img {
    // min-height: 100px;
    height: 150px;
    width: auto;
  }
`;

export const CardContent = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  padding: 30px 25px;
  /* margin-left: 1rem; */
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3); */
  border-bottom: 1.5px solid #e6e7ec;
`;

export const BrandName = styled.span`
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 400; */
  font-size: 12px;
  /* line-height: 29px; */
  color: ${({ theme }) => theme.colors.gray};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  /* margin: 16px; */
  width: 90%;
  // margin: 16px auto;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 18px;
`;

export const ProductName = styled.label`
  /* font-family: "Roboto";
  font-style: normal; */
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
  /* margin-top: 8px; */
`;

export const Compatibility = styled.span<{ value: number }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};

  > span {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 16px;

    color: ${({ theme, value }) => getColorByQuartile(value, theme)};

    margin-left: 6px;
  }
`;

export const ProductIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

export const CardHeader = styled.div`
  width: 100%;
  //   padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  //   > div {
  //     border: 2px solid blue;

  //     :first-child {
  //       width: 70%;
  //       display: flex;
  //       flex-direction: column;
  //       color: ${({ theme }) => theme.colors.black};

  //       > span {
  //         border: 2px solid black;
  //         font-size: 15px;
  //         > span {
  //           font-size: 15px;
  //           font-weight: bold;
  //           margin-left: 5px;
  //           color: ${({ theme }) => theme.colors.selected};
  //         }
  //       }
  //       > label {
  //         font-size: 20px;
  //         font-weight: bold;
  //         margin-top: 5px;
  //         margin-bottom: 5px;
  //       }
  //     }

  //     :last-child {
  //       margin-left: 1rem;
  //       width: 25%;
  //       height: 40px;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //       flex-direction: row;
  //       flex-wrap: wrap;
  //     }
  //   }
`;

export const CapsuleContainer = styled.div`
  width: 49px;
  height: 73px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fe;
  border-radius: 8px;
  > svg {
    height: 35px;
    width: 35px;
  }
  > label {
    /* margin-left: 5px; */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const MedicineWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
  // border: 2px solid red;
`;

export const MedicineTimeContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  // margin: 0 auto;

  // border: 2px solid blue;

  div {
    display: flex;
    align-items: center;
    border-radius: 4px;

    padding: 0.75rem 1rem;

    > svg {
      height: 25px;
      width: 25px;
    }
    > label {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.black};
      margin-left: 8px;
    }

    :last-child:not(:first-child) {
      margin-left: 2rem;
    }
  }
`;

export const ToogleNumber = styled.input`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-align: center;
  margin: 0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.gray};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelButton = styled.div`
  justify-content: space-between;

  // border: 2px solid green;

  :last-child {
    margin-left: 1rem;
    > select {
      width: 100%;
    }
  }
  display: flex;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50px;
  padding: 1rem;
  width: 120px;

  align-items: center;
`;

export const SelectMonth = styled.select`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};

  > option {
    // border: 2px solid red;
  }
`;

export const ProductsButtonsContainer = styled.div`
  display: flex;
  width: auto;
  overflow: hidden;

  // border: 2px solid red;
`;

export const PriceText = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const AddToCartButton = styled.button<{ isAdded: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.selected};
  align-items: center;
  padding: 0.8rem;
  border-radius: 80px;
  cursor: pointer;
  width: 250px;
  position: relative;

  > svg {
    // border: 2px solid red;
    margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
    position: absolute;
    right: 12px;
  }

  > label {
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background: ${({ isAdded }) =>
      isAdded
        ? ``
        : `linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%)`};
  }

  ${({ isAdded, theme }) => isAdded && `background: ${theme.colors.midBlue}`}
`;

export const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  // margin: 0 auto;

  padding-left: 16px;
  padding-right: 16px;

  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;

  // border: 2px solid red;
`;

// export const CapsulePerDay = styled.div`
//   width: 100%;
//   background: ${({ theme }) => theme.colors.white};
// `
