import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as AnimationMobile2 } from "../../assets/animation/validateBasket/animation-mobile-02.svg";
import { ReactComponent as AnimationMobile1 } from "../../assets/animation/validateBasket/checkout_animation.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/svg/arrow.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/svg/email.svg";
import { ReactComponent as MobileIcon } from "../../assets/icons/svg/mobile.svg";

import { ReactComponent as Amazon } from "../../assets/animation/validateBasket/amazon.svg";
import {
  ReactComponent as Kpsule,
  ReactComponent as MediMarket,
} from "../../assets/pharmacy-logos/pharmacy-13-medi-market.svg";

import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { GlobalContext } from "../../context/global";
import { SetupAnswerContext } from "../../context/setupAnswer";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { useViewport } from "../../hooks/Viewport/useViewport";
import { ShoppingCartSidebar } from "./components/ShoppingCartSidebar";
import {
  Container,
  ContainerFooter,
  ContainerMobile,
  Footer,
  Footeritems,
  ItemText,
  Title,
} from "./style";

const ValidateBasket = () => {
  const [showAnimation1, setShowAnimation1] = useState(true);
  const [showAnimation2, setShowAnimation2] = useState(false);
  const { shoppingCart } = useContext(ShoppingCartContext);
  const { isUnderMobile } = useViewport();

  const { t } = useTranslation();

  const { pharmacyId } = useContext(SetupAnswerContext);
  const { userName } = useContext(GlobalContext);
  const renderPartnerLogo = () => {
    if (pharmacyId == 5) {
      return <MediMarket className="amazon" />;
    } else {
      return <Amazon className="amazon" />;
    }
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => setShowAnimation1(false), 2000);
  //   setTimeout(() => setShowAnimation2(true), 2000);
  //   setTimeout(() => {
  //     shoppingCart.forEach((item) => {
  //       window.open(item.product.links?.fr, "_blank");
  //     });
  //     navigate("/");
  //   }, 4000);
  // }, []);

  return (
    <>
      {isUnderMobile ? (
        <Page screen="validate-basket" hideHeader>
          <ContainerMobile
            showAnimation1={showAnimation1}
            showAnimation2={showAnimation2}
          >
            <AnimationMobile1 className="animation-1" />
            <AnimationMobile2 className="animation-2" />

            <ContainerFooter
              showAnimation1={showAnimation1}
              showAnimation2={showAnimation2}
            >
              <Title>{t("screens.validate.product_list")}</Title>
              {/* {renderPartnerLogo()} */}
              <Kpsule className="kpsule" />
            </ContainerFooter>
          </ContainerMobile>
        </Page>
      ) : (
        <Page screen="validate-basket" hideHeader validateRowPage noSidebar>
          <Container
            showAnimation1={true}
            // showAnimation2={showAnimation2}
          >
            <Kpsule className="kpsule" />
            <Title>
              <span>{userName}</span>, {t("screens.validate.product_list")}
            </Title>
            <AnimationMobile1 className="animation-1" />
            {/* <AnimationMobile2 className="animation-2" /> */}

            <Footer>
              <Footeritems>
                <EmailIcon width={90} />
                <ItemText
                  dangerouslySetInnerHTML={{
                    __html: t("components.shoppingCartSidebar.email"),
                  }}
                />
              </Footeritems>

              <Footeritems>
                <MobileIcon width={90} />
                <ItemText
                  dangerouslySetInnerHTML={{
                    __html: t("components.shoppingCartSidebar.qr_code"),
                  }}
                />
              </Footeritems>

              <ArrowRight />
            </Footer>
          </Container>
          <ShoppingCartSidebar />
          {/* <div style={{ width: 434, height: '100vh',backgroundColor: 'red', position: 'fixed', right: 0 }}>
            test
          </div> */}
        </Page>
      )}
    </>
  );
};

export default ValidateBasket;
