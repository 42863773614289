import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { IoEllipse } from "react-icons/io5";
import { animationData } from "../../assets/animation/kpsuleIconLoading/data.js";
import video01 from "../../assets/video/01.gif";
import video02 from "../../assets/video/02.gif";
import video03 from "../../assets/video/03.gif";
import video04 from "../../assets/video/04.gif";
import { useViewport } from "../../hooks/Viewport/useViewport";

import { ReactComponent as CompleteMediMarketLogo } from "../../assets/pharmacy-logos/pharmacy-13-medi-market.svg";

export const LoadingScreen = () => {
  const { t } = useTranslation("loading");
  const [step, setStep] = useState(0);
  const [stepTimes] = useState([4000, 8900, 11110, 5970]);

  const [phrases] = useState([
    "Notre <label>intelligence artificielle</label> est en train d’analyser vos réponses en les croisant à partir d’une base de plus de <label>70,000 publications scientifiques</label>",
    "Notre intelligence crée des <label>combinations personnalisées</label> de suppléments alimentaires qui <label>ensemble</label> peuvent répondre <label>jusqu’a 100% de vos besoins de Santé</label>",
    "Decouvrez aussi une recommendation personnalisée des <label>meilleurs produits</label> du magasin, classés par <label>ordre de pertinence</label> selon les besoins Santé qu’ils vous aide a résoudre",
    "Vous avez aussi accès au classement des <label>meilleurs nutriments</label>",
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step < 3) {
        setStep(step + 1);
      }
    }, stepTimes[step]);

    return () => clearTimeout(timer);
  }, [step]);

  const renderGif = () => {
    switch (step) {
      case 0:
        return <img src={video01} alt="" />;
      case 1:
        return <img src={video02} alt="" />;
      case 2:
        return <img src={video03} alt="" />;
      case 3:
        return <img src={video04} alt="" />;
    }
  };

  const renderPhrase = () => {
    return phrases[step];
  };

  const { isUnderMobile } = useViewport();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <StyledLoadingScreen>
      <LogoContainer>
        <CompleteMediMarketLogo />
      </LogoContainer>
      <VideoContainer removeShadow={step === 0}>{renderGif()}</VideoContainer>
      <SubtitleContainer>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: renderPhrase(),
          }}
        ></Subtitle>
      </SubtitleContainer>
      <CarouselDots>
        <IoEllipse size={"1.2rem"} color={step === 0 ? "#00A984" : "#545976"} />
        <IoEllipse size={"1.2rem"} color={step === 1 ? "#00A984" : "#545976"} />
        <IoEllipse size={"1.2rem"} color={step === 2 ? "#00A984" : "#545976"} />
        <IoEllipse size={"1.2rem"} color={step === 3 ? "#00A984" : "#545976"} />
      </CarouselDots>
    </StyledLoadingScreen>
  );
};

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 233px;
  height: 60px;
  flex-shrink: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  > svg {
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: auto 0;
  height: 100px;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  color: #545976;
  max-width: 812px;
  text-align: center;

  > label {
    font-weight: 700;
  }
`;

const VideoContainer = styled.div<{ removeShadow: boolean }>`
  height: 100%;
  max-height: 60%;
  width: auto;
  margin-top: 30px;
  > img {

    transition: box-shadow 1s ease;
    width: auto;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    ${({ removeShadow }) =>
    removeShadow &&
    `
      box-shadow: none;
    `}}
`;

const CarouselDots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 31px;
  margin-bottom: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  animation: grow 2s linear 1;
  animation-delay: 7s;
  animation-fill-mode: forwards;

  border: 2px solid red;

  .loading-animation {
    display: inline-block;
    background-image: url(path/to/your/image.png);
    animation: rotate 6s linear 1;
  }

  .loading-animation-name {
    width: 0px;
    opacity: 0;
    margin-left: 20px;
    margin-top: 10px;
    animation: appear 1s linear 1;
    animation-delay: 5s;
    transition: width 1s ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.4);
    }
  }

  @keyframes appear {
    0% {
      width: 0px;
      opacity: 0;
    }

    100% {
      width: 420px;
      opacity: 1;
    }
  }

  @keyframes rotate {
    0% {
      opacity: 0;
      transform: rotate(-360deg);
    }

    20% {
    }

    80% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;

const StyledLoadingScreen = styled.div`
  background: #fcfbfc;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {

  }
`;
