import styled from "styled-components";
import bannerBackground from "../../../../../assets/banners/banner-background.png";
import {
  AlertColors,
  getAlertGradient,
} from "../../../../../components/Cards/AlertCard/style";
import { getGradient } from "../../../../../utils/getBannerGradient";

export const Container = styled.div<{ viewMore: boolean; index: number }>`
  /* width: 90%; */
  min-width: 345px;
  /* min-height: 416px; */
  height: 356px;
  display: flex;
  /* justify-content: space-around; */
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  flex-direction: column;
  margin-top: 18px;
  padding: 16px;
  position: relative;

  ${({ theme }) => theme.shadow}
  ${({ viewMore, index }) =>
    !viewMore &&
    index > 2 &&
    `
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    `} 
    
  section {
    /* margin-right: 12px; */
    margin-top: -5px;
    padding-left: 5px;
  }
`;

export const TitleContainerVitamin = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  // margin-left: 8px;

  > label {
    /* margin-top: 1rem; */
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
    text-transform: capitalize;
  }
`;

export const ScrollButtonsContainer = styled.div<{
  hasDietInformation: boolean;
}>`
  position: absolute;
  top: 16px;
  right: ${({ hasDietInformation }) => (hasDietInformation ? "238px" : "16px")};
`;

interface FieldsOfActionLabelProps {
  dietInformation?: number;
}

export const FieldsOfActionLabelContainer = styled.div<FieldsOfActionLabelProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  /* margin-top: ${({ dietInformation }) =>
    dietInformation ? "0px" : "60px"}; */
`;

export const FieldsOfActionLabel = styled.label<FieldsOfActionLabelProps>`
  /* position: absolute; */
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};
  /* top: 24px;
  left: 320px; */

  margin-left: 5px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const OldContainer = styled.div.attrs(
  (props: { viewMore: boolean; index: number }) => props
)`
  margin-top: 1.5rem;
  width: 100%;
  height: 236px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  transition: opacity 1s ease-out;
  opacity: 1;

  ${({ theme }) => theme.shadow}
  ${({ viewMore, index }) =>
    !viewMore &&
    index > 2 &&
    `
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
  /* left: 24px; */

  > div {
    :not(:first-child) {
      margin-top: 5px;
    }
  }
`;

export const AlertContainer = styled.div<{ color: AlertColors }>`
  height: 27.5px;
  width: 27.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  > svg {
    height: 15.28px;
    width: 15.28px;
  }
  background: ${({ color, theme }) =>
    color === "gray" ? theme.colors.grayBgButton : getAlertGradient(color)};
`;

export const StarContainer = styled.div<{ isInTopTen: boolean }>`
  height: 27.5px;
  width: 27.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 15.28px;
    width: 15.28px;
  }
  ${({ theme, isInTopTen }) =>
    isInTopTen
      ? theme.blueGradient
      : `background: ${theme.colors.grayBgButton}`}
`;

export const Fills = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* align-items: flex-start; */
  justify-content: space-between;
  // border: 2px solid green;
  width: 100%;
  /* min-width: 320px; */
  position: relative;
  /* padding: 24px; */

  /* > div {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    } */

  /* > label {
    margin-top: 1rem;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
  } */
`;

export const NutrientFills = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  //   border: 2px solid red;
  margin-top: 6px;
  opacity: 0.8;
`;

export const CompositionField = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 2px solid orange;

  > label {
    font-size: 14px;
    line-height: 1;
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
  }
`;

export const FieldsOfAction = styled.div`
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  flex: auto;
  overflow-x: auto;
  position: relative;
  z-index: 99;

  border: 2px solid red;
  padding-left: 12px;
  padding-right: 12px;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.black};
  }
  > div {
    margin-top: 10px;
  }
`;

export const Field = styled.div<{ border?: "gradient" | "pink" | "none" }>`
  padding: 2px;
  height: 110px;
  width: 81px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  flex-shrink: 0;

  border: 1px transparent;
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  /* margin-right: 1rem; */

  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  ${({ border }) => {
    switch (border) {
      case "gradient":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, rgba(49, 53, 198, 1), rgba(0, 170, 255, 1));`;
      case "pink":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, rgba(237, 159, 159, 1), rgba(255, 164, 113, 0.97));`;
      default:
        return;
    }
  }};

  > svg {
    bottom: -8px;
    position: absolute;
  }
`;

export const FieldName = styled.label<{ nameSize: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  ${({ nameSize }) => {
    if (nameSize > 18) return `font-size: 11px;`;
  }}

  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.gray};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 28px;
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 80%;
`;

export const ProgressContainer = styled.div<{ border?: "gradient" | "pink" }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  > svg * {
    fill: ${({ border }) => border === "pink" && "#ED9F9F"};
  }
`;

export const MockCircle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`;

export const MockName = styled.div`
  margin-top: 1rem;
  height: 4px;
  padding: 4px;
  width: 40%;
  background: ${({ theme }) => theme.colors.gray};
  opacity: 0.8;
  border-radius: 8px;
  // border: 2px solid red;
`;

export const AbsoluteIcon = styled.div``;

export const DietInformationCarouselContainer = styled.div`
  width: 22px;
  /* position: relative; */
`;

export const DietInformationContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 15px 0 8px;
  /* margin: 16px 0; */
  /* overflow: scroll; */
  z-index: 0;
`;

export const DietInformation = styled.div<{
  modalInformation?: boolean;
  information?: string;
  score: number;
  backgroundType: string;
  isSelected?: boolean;
}>`
  width: 100%;
  height: 62px;
  // border: 2px solid red;
  border-radius: 8px;
  background: url(${bannerBackground}),
    linear-gradient(
      to right,
      ${({ score, backgroundType }) => getGradient(score, backgroundType)}
    );
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  /* position: absolute; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 99;
  opacity: 1;
  position: relative;
  filter: drop-shadow(0px 4px 4px #ead6f1);

  /* ${({ isSelected }) =>
    isSelected &&
    `
      z-index: 99;
      opacity: 1;
  `} */
`;

export const DietInformationDefault = styled.div`
  width: 100%;
  height: 62px;
  // border: 2px solid red;
  border-radius: 8px;
  background: #f5f7fe;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  /* position: absolute; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 99;
  opacity: 1;
  position: relative;
  filter: drop-shadow(0px 4px 4px #e1e1e1);
`;

export const DietInformationProgressIcon = styled.div`
  margin-left: 10px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

export const DietInformationProgressIconSimplesCard = styled.div`
  height: 45px;
  width: 45px;
`;

export const DietInformationTitleContainer = styled.div`
  width: 100%;
  // border: 2px solid black;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const DietInformationTitleContainerDefault = styled.div`
  width: 60%;
  // border: 2px solid black;
  display: flex;
  align-items: center;
  padding: 0 9px;
`;

export const DietInformationTitleContainerSimpleCard = styled.div<{
  modalInformation?: boolean;
  information?: string;
  isSelected?: boolean;
}>`
  visibility: hidden;
  width: 120px;
  background-color: black;
  text-align: center;
  padding: 10px;
  line-height: 17px;
  border-radius: 6px;

  position: absolute;
  bottom: 105%;
  z-index: 1;

  visibility: ${({ isSelected, modalInformation }) =>
    isSelected && modalInformation ? "visible" : "hidden"};

  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    transition: opacity 0.2s ease-in-out;
    border-color: #000 transparent transparent transparent;
    z-index: 999;
  }
  /* width: 100%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 0 16px;

  position: absolute;
  top: -150%;
  left: 0;

  z-index: 999; */
`;

export const DietInformationTitle = styled.span`
  font-size: 13px;
  /* line-height: 18px; */
  color: ${({ theme }) => theme.colors.white};
`;

export const DietInformationTitleDefault = styled.span`
  font-size: 13px;
  /* line-height: 18px; */
  color: #545976;
  opacity: 0.7;
`;

export const DietInformationCarouselButtons = styled.div`
  height: 4px;
  width: 100%;
  padding: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const CarouselButton = styled.div<{ actual: number; value: number }>`
  height: 4px;
  width: 24px;
  background: ${({ theme, actual, value }) =>
    actual === value ? theme.colors.white : "transparent"};
  border-radius: 8px;
  border: 1px solid white;
  :not(:last-child) {
    margin-right: 4px;
  }

  cursor: pointer;
`;

export const DietInformationImage = styled.img`
  width: 19px;
  height: 19px;
`;

export const DietInformationImageDefault = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 10px;
  opacity: 0.7;
`;

export const SeeAllRelatedPublicationsMobile = styled.label<{
  hasDietInformation: boolean;
}>`
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.selected};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  > svg {
    margin-left: 8px;
  }

  /* :hover {
    text-decoration: underline;
  } */
`;

// &::after {

//   transition: opacity 0.2s ease-in-out;
//   text-align: center;
//   line-height: 16px;
//   content: '${({information}) => information ? information : ' ffff'}';

//   background: #FFFFFF;
//   box-shadow: 0px 3px 6px #EDEDED;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px;

//   position: absolute;
//   bottom: 110%;
//   left: 0;

//   opacity: ${({isSelected, modalInformation}) => (isSelected && modalInformation) ? 1 : 0};

// }
// &::before {
//     content: " ";
//     position: absolute;
//     bottom: 85%;
//     width: 3px;
//     height: 3px;
//     border-left: 13px solid transparent;
//     border-top: 16px solid #FFF;
//     border-right: 13px solid transparent;
//     transition: opacity 0.2s ease-in-out;
//     opacity: ${({isSelected, modalInformation}) => (isSelected && modalInformation) ? 1 : 0};

//     z-index: 999;
//   }
