import { ProductProps } from "../../../../api/types";
import { HealthCategoriesContainer } from "../../../NutrientDetails/tabs/FieldsOfAction/style";
import HealthCategoriesCard from "../../../ProductDetails/tabs/FieldsOfAction/components/HealthCategoriesCard/HealthCategoriesCard";
import { FieldOfActionContainer } from "./styles";

interface FieldOfActionProps {
  product: ProductProps;
}

export function FieldOfAction({ product }: FieldOfActionProps) {
  return (
    <FieldOfActionContainer>
      <HealthCategoriesContainer>
        {product.fieldsOfAction.map((field) => (
          <HealthCategoriesCard fieldsOfAction={field} />
        ))}
      </HealthCategoriesContainer>
    </FieldOfActionContainer>
  );
}
