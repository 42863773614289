import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../components/Cards/Nutrient/style";

interface ContainerProps {
  clicked: boolean;
  elements?: number;
}

const iconBox = css`
  height: 35px;
  width: 35px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: ${({ elements }) =>
    elements ? 100 / elements - 2 + "%" : 300 + "px"};
  height: 275px;
  padding: 8px;
  margin: 8px;
  /* max-width: 420px; */
  border: 2px solid ${({ clicked }) => (clicked ? "#0CA986" : "transparent")};
  border-radius: 8px;
  box-shadow: ${({ clicked }) =>
    clicked ? "none" : "0px 6px 8px 0px rgba(0, 0, 0, 0.3)"};
  display: flex;
  flex-direction: column;
  /* box-shadow:  */
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  cursor: pointer;

  ${({ clicked }) =>
    clicked
      ? `.triangulo {
      display: block;
      height: 20px;
      width: 20px;
      background-color: inherit;
      border: inherit;
      position: absolute;
      bottom: -10px;
      left: calc(50% - 10px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      transform: rotate(-45deg);
      border-radius: 0 0 0 0;
    }`
      : ""}

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.selected};
    box-shadow: none;
    transition: 0.3s;

    .triangulo {
      display: block;
      height: 20px;
      width: 20px;
      background-color: inherit;
      border: inherit;
      position: absolute;
      bottom: -10px;
      left: calc(50% - 10px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      transform: rotate(-45deg);
      border-radius: 0 0 0 0;
    }
  }

  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;

  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;

  @-webkit-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    min-width: 14rem;

    justify-content: space-between;

    padding: 1rem 0.5rem;
  }
`;

export const Product = styled.img`
  height: 90px;
  width: auto;
  /* max-width: 70px; */
  /* max-height: 70px; */
  image-rendering: auto;
`;

export const ProductContainer = styled.div`
  width: 100%;
  padding: 8px;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`;

export const Footer = styled.div<{ value: number }>`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  /* margin-top: 8px; */
  // border: 2px solid red;

  > b {
    // border: 2px solid blue;
    text-align: center;
    margin-top: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding-left: 8px;
    padding-right: 8px;
    color: ${({ theme }) => theme.colors.gray};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray};
  }
  span {
    margin-top: 8px;
    font-size: 16px;
    text-align: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.black};
    align-self: center;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    > span {
      font-weight: bold;
      color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    }
  }

  > div {
    margin-top: 4px;
    > label {
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 8px;
  width: 100%;
  flex-flow: wrap;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const CapsuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 6px 4px 6px 4px;
  > svg {
    height: 18px;
    width: 18px;
  }

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

interface StarContainerProps {
  isTheMostRecommended?: boolean;
}

export const StarContainer = styled.div<StarContainerProps>`
  ${iconBox}
  ${({ theme, isTheMostRecommended }) => isTheMostRecommended ? theme.goldGradient : theme.blueGradient}
`;

// export const AlertContainer = styled.div`
//   ${iconBox}
//   ${({ theme }) => theme.redGradient}
// `;

export const AlertContainer = styled.div.attrs(
  (props: { importance: string }) => props
)`
  ${iconBox}
  ${({ theme, importance }) =>
    importance === "hasAvoid"
      ? theme.redGradient
      : importance === "hasContraindications"
      ? theme.orangeGradient
      : theme.yellowGradient} /* ${({ theme }) => theme.redGradient} */
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  > label,
  b {
    margin-left: 5px;
    font-size: 15px;
  }
`;
