import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { ReactComponent as LogoBgMobile } from "../../../assets/pharmacy-logos/pharmacy-13-loading-white.svg";
import logoMobile from "../../../assets/pharmacy-logos/pharmacy-13-medi-market-white.svg";
import { ReactComponent as MedicineIcon } from "../../../assets/sidebar/medicine.svg";

import { HiArrowSmLeft } from "react-icons/hi";
import { useViewport } from "../../../hooks/Viewport/useViewport";
import { sidebarElements } from "../../../utils/constants/sidebarElements";
import { SidebarMobileContainer } from "./styles";

interface SidebarMobileProps {
  clicked: boolean;
  setClicked: () => void;
}

export function SidebarMobile({ clicked, setClicked }: SidebarMobileProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useViewport();

  function handleNavigate(item: string) {
    sessionStorage.removeItem("home_best_nutrients_scroll_position");
    sessionStorage.removeItem("home_best_products_scroll_position");
    sessionStorage.removeItem("nutrient_tab");
    setClicked();
    const setNavigation = setTimeout(() => {
      navigate(item);
    }, 400);
    return () => clearTimeout(setNavigation);
  }

  return (
    <SidebarMobileContainer
      width={width}
      className={clicked ? "nav-menu active" : "nav-menu"}
      isOpen={clicked}
    >
      <LogoBgMobile className="logo-bg" />
      <HiArrowSmLeft size={25} onClick={setClicked} />

      <img src={logoMobile} alt="" />
      <h4>{t("components.navMobile.the_right_supplements")}</h4>
      <ul>
        {sidebarElements.map((item) => {
          if (item.key === "recommended-packs") {
            return;
          }

          return (
            <li key={item.path} onClick={() => handleNavigate(item.path)}>
              <item.icon />
              <span>{t(item.title)}</span>
            </li>
          );
        })}
      </ul>

      <button onClick={() => handleNavigate("/recommended-packs")}>
        <MedicineIcon />
        {t("components.sidebar.recommendedPacks")}
      </button>
    </SidebarMobileContainer>
  );
}
