import React, { useContext } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ReactComponent as ShoppingIcon } from "../../assets/content/shopping.svg";
import { ShoppingCartContext } from "../../context/shoppingCart";
import {
  CartProgressContainer,
  Circle,
  Commom,
  Container,
  Content,
  IconContainer,
} from "./style";

const Header: React.FC<{ headerChildren?: () => JSX.Element }> = ({
  headerChildren,
}) => {
  const { inCartNutritionalProgress, openShoppingCartSidebar, getTotalProducts } =
    useContext(ShoppingCartContext);

  return (
    <Container>
      <Content>{headerChildren && headerChildren()}</Content>
      <Commom>
        {/* {process.env.NODE_ENV !== "production" && <LanguagePicker />} */}
        {/* <RoundedProgress
          size={67}
          progress={inCartNutritionalProgress}
          thickness={12}
        >
          <IconContainer onClick={openShoppingCartSidebar}>
            <ShoppingIcon />
          </IconContainer>
        </RoundedProgress> */}

        <CartProgressContainer onClick={openShoppingCartSidebar}>
          <CircularProgressbarWithChildren
            value={inCartNutritionalProgress}
            strokeWidth={12}
            styles={{
              trail: {
                stroke: `#00000000`,
              },

              root: {
                background: "white",
                borderRadius: "200px",
                border: "2px solid #F5F7FE",
              },
            }}
          >
            <IconContainer onClick={openShoppingCartSidebar}>
              <ShoppingIcon />
            </IconContainer>
          </CircularProgressbarWithChildren>
          <Circle>
            <label>{getTotalProducts()}</label>
          </Circle>
        </CartProgressContainer>
      </Commom>
    </Container>
  );
};

export default Header;
