import React from "react";
import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { NutrientProps } from "../../../api/types";
import { ReactComponent as StarIcon } from "../../../assets/content/star.svg";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";
import { getHealthGoalsIcon } from "../../../utils/getHealthGoalsIcon";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";
import { getSymptomsIcon } from "../../../utils/getSymptomsIcon";
import RoundedProgress from "../../RoundedProgress";
import StandardTooltip from "../../Tooltip";
import {
  AlertContainer,
  Body,
  ButtonLabel,
  Circle,
  Container,
  Footer,
  Header,
  HealthGoalAndSymptomContent,
  HealthGoalAndSymptomScoreProgress,
  HealthGoalAndSymptomTitle,
  HealthGoalsAndSymptomsContainer,
  HealthGoalsAndSymptomsIcon,
  LearnMoreLabel,
  NoneSubtitle,
  NoneTitle,
  Nutrient,
  NutrientCompability,
  NutrientInfo,
  NutrientName,
  StarContainer,
  Subtitle,
} from "./style";
interface NutrientDetailsProps {
  nutrient: NutrientProps;
}

const NutrientCard: React.FC<NutrientDetailsProps> = ({ nutrient }) => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const {
    image,
    isInTop,
    hasSynergy,
    hasPrecaution,
    healthGoals,
    symptoms,
    name,
    score,
    dosageAmountUnit,
    dosageAmountValue,
  } = nutrient;

  return (
    <Container>
      <Header
        onClick={() => {
          navigation("/nutrient-details", {
            state: { nutrient, scroll: false },
          });
        }}
      >
        <Nutrient
          src={getImageCompletePath("nutrient", image)}
          alt="nutrient"
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
        <NutrientInfo>
          {isInTop && (
            <StarContainer id={`nutrient-is-in-top-ten-${nutrient.id}`}>
              <StarIcon />
            </StarContainer>
          )}
          {isInTop && (
            <StandardTooltip
              anchor={`nutrient-is-in-top-ten-${nutrient.id}`}
              color="blue"
              text={t("hovers.nutrient_is_recommended")}
              place="bottom"
            />
          )}
          {hasPrecaution && (
            <AlertContainer id={`nutrient-has-alert-${nutrient.id}`}>
              <IoAlertOutline color="white" />
            </AlertContainer>
          )}
          {hasPrecaution && (
            <StandardTooltip
              anchor={`nutrient-has-alert-${nutrient.id}`}
              color="orange"
              text={t("hovers.nutrient_has_alert")}
              place="bottom"
            />
          )}
        </NutrientInfo>
      </Header>
      <Body
        onClick={() => {
          navigation("/nutrient-details", {
            state: { nutrient, scroll: false },
          });
        }}
      >
        <NutrientName>
          {`${name}`}
          {
            <span>
              <br />
              {`${dosageAmountValue} ${dosageAmountUnit}/${t("common.day")}`}
            </span>
          }
        </NutrientName>
        {/* <NutrientCompability value={Math.round(score)}>
          {t("screens.home.tabs.bestNutrients.your_compatibility")}
          <span>{`${Math.max(0, Math.round(score))}/100`}</span>
        </NutrientCompability> */}
        <HealthGoalsAndSymptomsContainer>
          {healthGoals.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>
                    {t("screens.home.tabs.bestNutrients.health_goals")}
                  </label>
                  <Circle>
                    <label>{healthGoals.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <Subtitle>{t(`healthGoals.${healthGoals[0].name}`)}</Subtitle>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={healthGoals[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getHealthGoalsIcon(healthGoals[0].name, 22)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {symptoms.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>{t("screens.home.tabs.bestNutrients.symptoms")}</label>
                  <Circle>
                    <label>{symptoms.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <Subtitle>{symptoms[0].name}</Subtitle>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={symptoms[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getSymptomsIcon(symptoms[0].categoryId)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {healthGoals.length === 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <NoneTitle />
                </HealthGoalAndSymptomTitle>
                <span>
                  <NoneSubtitle />
                </span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={0}
                  thickness={5}
                  grayForeground
                  backgroundColor="#f5f7fe"
                >
                  <div></div>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {symptoms.length === 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <NoneTitle />
                </HealthGoalAndSymptomTitle>
                <span>
                  <NoneSubtitle />
                </span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={0}
                  thickness={5}
                  grayForeground
                  backgroundColor="#f5f7fe"
                >
                  <div></div>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
        </HealthGoalsAndSymptomsContainer>
      </Body>
      <Footer>
        <div
          onClick={() => {
            navigation("/nutrient-details", {
              state: { nutrient, scroll: false },
            });
          }}
        >
          <LearnMoreLabel
            textLength={t("screens.home.tabs.bestNutrients.learn_more").length}
          >
            {t("screens.home.tabs.bestNutrients.learn_more")}
          </LearnMoreLabel>
        </div>
        <div>
          <button
            onClick={() => {
              navigation("/nutrient-details", {
                state: { nutrient, scroll: true },
              });
            }}
          >
            <ButtonLabel
              textLength={
                t("screens.home.tabs.bestNutrients.best_products").length
              }
            >
              {t("screens.home.tabs.bestNutrients.best_products")}
            </ButtonLabel>
          </button>
        </div>
      </Footer>
    </Container>
  );
};
export default NutrientCard;
