import styled from "styled-components";

export const CompositionContainer = styled.div`
  margin-top: 34px;
  // border: 2px solid red;
`;

export const CompositionTitle = styled.label`
  // border: 2px solid green;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  align-items: center;
  // padding-left: 10px;
  // position: absolute;
`;

export const CompositionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border: 2px solid red;
  width: 100%;
`;

export const CompositionCountLeft = styled.label`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const CircleCompositionNumber = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Container = styled.div`
  padding: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  height: 236px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  ${({ theme }) => theme.shadow}
`;

export const ContainerScrollButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  /* padding-right: 24px;
  margin: 15px 0 -20px 0; */
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;

  > div {
    :not(:first-child) {
      margin-top: 10px;
    }
  }
`;

export const Fills = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  //   border: 2px solid green;
  width: 320px;
  position: relative;

  > label {
    margin-top: 1rem;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
  }
`;

export const NutrientFills = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  //   border: 2px solid red;
  margin-top: 1rem;
  opacity: 0.5;
`;

export const CompositionField = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 2px solid orange;

  > label {
    font-size: 20px;
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
  }
`;

export const FieldsOfAction = styled.div`
  margin-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  //   border: 2px solid red;

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.black};
  }
  > div {
    margin-top: 10px;
  }
`;

export const Field = styled.div<{ border?: "gradient" | "pink" | "none" }>`
  padding: 2px;
  height: 120px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px transparent;
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  margin-right: 1rem;

  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  ${({ border }) => {
    switch (border) {
      case "gradient":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, rgba(49, 53, 198, 1), rgba(0, 170, 255, 1));`;
      case "pink":
        return `background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, rgba(237, 159, 159, 1), rgba(255, 164, 113, 0.97));`;
      default:
        return;
    }
  }};
  //   border: 2px solid black;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;

    > svg {
      height: 20px;
      width: 20px;
    }
  }
  > label {
    margin-top: 15px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    text-align: center;
  }
`;
export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 80%;
`;

export const ProgressContainer = styled.div<{ border?: "gradient" | "pink" }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  > svg * {
    fill: ${({ border }) => border === "pink" && "#ED9F9F"};
  }
`;

export const AbsoluteIcon = styled.div``;

export const ViewMoreContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const ViewMoreText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.selected};
`;
