import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NutrientProps, ProductProps } from "../../api/types";
import {
  HeaderContainer,
  HeaderContainerMobile,
  HeaderLabel,
} from "../../styles/commomStyles";
import {
  AlertsContainer,
  BackButton,
  DietInformationImage,
  Footer,
  HealthGoalsContainer,
  HorizontalScrollContainer,
  NutritionBanner,
  ScrollButton,
  TabContainer,
} from "./style";

import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import forkAndKnifeImg from "../../assets/food_alert_icons/circle_fork_and_fire.svg";
import Symptom from "../../components/Cards/Sympthon";
import HorizontalScrollView from "../../components/HorizontalScroll";
import ProgressCard from "../../components/ProgressCard";
import { GlobalContext } from "../../context/global";
import { getHealthGoalsIcon } from "../../utils/getHealthGoalsIcon";
import { getSymptomsIcon } from "../../utils/getSymptomsIcon";
// import { Card } from "./components/Card";
import { IoAlert } from "react-icons/io5";
import AlertCardMobile from "../../components/Cards/AlertCardMobile";
import Page from "../../components/Page";
import { getDrugInteractionsIcon } from "../../utils/getDrugInteractionIcon";
import getDrugInteractionPhrasesFormatted from "../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../utils/getInnerHtmlInsideDiv";
import CardSimpleMobile from "../ProductDetailsMobile/components/CardSimpleMobile";
import TabViewMobile from "../ProductDetailsMobile/components/TabViewMobile";
import { CardMobile } from "./components/CardMobile";
import BestProducts from "./tabs/BestProducts";
import NutrientFieldsOfAction from "./tabs/FieldsOfAction";

export type NutrientViewType = {
  state: {
    nutrient: NutrientProps;
    scroll: boolean;
  };
};

const NutrientDetailsMobile: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { nutrient, scroll },
  } = useLocation() as NutrientViewType;
  const sliderRef = createRef<HTMLDivElement>();
  const navigation = useNavigate();
  const [bestProduct, setBestProduct] = useState<ProductProps | undefined>();
  const [cheaperProduct, setCheaperProduct] = useState<
    ProductProps | undefined
  >();
  const [allProductsWithNutrient, setAllProductsWithNutrient] = useState<
    ProductProps[]
  >([]);
  const [scrollPosition, setPosition] = useState(0);
  const { products } = useContext(GlobalContext);

  const bestProductsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const handleScrollButton = () => {
    // @ts-ignore
    !scrollPosition && bestProductsRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (scroll && bestProductsRef !== null) {
      // @ts-ignore
      setTimeout(() => bestProductsRef.current.scrollIntoView(), 200);
    }
  }, [scroll, bestProductsRef]);

  useEffect(() => {
    setBestProduct(products[0]);
    // TODO cheaperProducts
    setCheaperProduct(products[1]);

    const nutrientProducts = products.filter((product) =>
      nutrient.productsId.includes(product.id)
    );
    setAllProductsWithNutrient(nutrientProducts);
  }, [products]);

  if (!bestProduct) {
    return <></>;
  }

  if (!cheaperProduct) {
    return <></>;
  }

  const RenderHeader = () => (
    <HeaderContainerMobile>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton onClick={() => navigation(-1)}>
          <AiOutlineArrowLeft size={25} fill="#fff" />
        </BackButton>
        <HeaderLabel>{/* <b>Best Products</b> */}</HeaderLabel>
      </div>
    </HeaderContainerMobile>
  );

  const getIconColor = (action: string) => {
    if (action === "Avoid") {
      return "red";
    }
    if (action === "Lower") {
      return "orange";
    }
    if (action === "Favor") {
      return "green";
    }
    return "gray";
  };

  const renderHeaderLogo = () => (
    <HeaderContainer>{/* <img src={logoMobile} alt="" /> */}</HeaderContainer>
  );

  return (
    <Page
      screen=""
      header={RenderHeader}
      logo={renderHeaderLogo()}
      removePadding
    >
      <CardMobile nutrient={nutrient} ref={bestProductsRef} />
      {nutrient.nutritionDeficiency > 0 &&
        nutrient.nutritionDeficiency <= 75 && (
          <NutritionBanner>
            {<DietInformationImage src={forkAndKnifeImg} />}
            <label>
              {t("screens.nutrientDetail.diet_deficiency", {
                nutritionDeficiency: nutrient.nutritionDeficiency,
              })}
            </label>
          </NutritionBanner>
        )}

      {nutrient.drugInteraction.length > 0 && (
        <AlertsContainer>
          <CardSimpleMobile title={t("common.alerts")}>
            <HorizontalScrollView gap={8}>
              {getDrugInteractionPhrasesFormatted(
                nutrient.drugInteraction,
                t
              ).map((alert, index) => {
                return (
                  <AlertCardMobile
                    icon={getDrugInteractionsIcon(alert.design)}
                    subtitle={t("common.drug_interaction")}
                    title={alert.drugName}
                    topIcon={<IoAlert />}
                    key={index}
                    anchor={`drug-interaction-alert-${index}`}
                    tooltipText={alert.phrase}
                    color={getIconColor(alert.action)}
                    tooltipChildren={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getInnerHtmlInsideDiv(alert.phrase),
                        }}
                      ></div>
                    }
                  />
                );
              })}
            </HorizontalScrollView>
          </CardSimpleMobile>
        </AlertsContainer>
      )}

      {nutrient.healthGoals.length > 0 && (
        <CardSimpleMobile title={t("screens.nutrientDetail.health_goals")}>
          <HealthGoalsContainer>
            {nutrient.healthGoals.map((healthGoal, index) => {
              return (
                <ProgressCard
                  key={index}
                  title={healthGoal.name}
                  icon={getHealthGoalsIcon(healthGoal.name, 40)}
                  progress={healthGoal.score}
                  height={10}
                />
              );
            })}
          </HealthGoalsContainer>
        </CardSimpleMobile>
      )}

      {nutrient.symptoms.length > 0 && (
        <CardSimpleMobile
          title={`${nutrient.symptoms.length} ${t(
            "screens.nutrientDetail.symptoms"
          )}`}
        >
          {/* <SymptomScrollButtonContainer>
          <ScrollButtons ref={sliderRef} length={174} />
        </SymptomScrollButtonContainer> */}
          <HorizontalScrollContainer>
            <HorizontalScrollView ref={sliderRef} size={180} gap={8}>
              {nutrient.symptoms.map((symptom, index) => {
                return (
                  <Symptom
                    key={index}
                    icon={getSymptomsIcon(symptom.categoryId)}
                    progress={symptom.score}
                    title={symptom.name}
                  />
                );
              })}
            </HorizontalScrollView>
          </HorizontalScrollContainer>
        </CardSimpleMobile>
      )}
      <div ref={bestProductsRef} />

      <TabContainer>
        <TabViewMobile
          backgroundColor="transparent"
          numberOfComponentesLabel={[
            nutrient.productsId.length,
            nutrient.fieldsOfAction.length,
          ]}
          labels={[
            t("screens.nutrientDetail.best_products"),
            t("screens.nutrientDetail.fields_of_action"),
          ]}
          contents={[
            <BestProducts
              allProducts={allProductsWithNutrient}
              betterProduct={bestProduct}
              cheaperProduct={cheaperProduct}
              nutrientName={nutrient.name}
            />,
            <NutrientFieldsOfAction fieldsOfAction={nutrient.fieldsOfAction} />,
          ]}
        />
      </TabContainer>

      <Footer scrollPosition={scrollPosition} scroll={scroll}>
        <ScrollButton onClick={handleScrollButton}>
          Product containing this nutrients
        </ScrollButton>
      </Footer>
    </Page>
  );
};

export default NutrientDetailsMobile;
