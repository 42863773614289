import React, {
  ChangeEvent,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductProps, SymptomType } from "../../api/types";
import { HeaderContainerMobile } from "../../styles/commomStyles";
import {
  AlertsContainer,
  BackButton,
  CompositionTabContainer,
  Footer,
  HeaderContainer,
  HealthGoalsContainer,
} from "./style";

import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import HoverButton from "../../components/Button/HoverButton";
import Symptom from "../../components/Cards/Sympthon";
import HorizontalScrollView from "../../components/HorizontalScroll";
import Page from "../../components/Page";
import ProgressCard from "../../components/ProgressCard";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { getCurrencyFormat } from "../../utils/getCurrencyString";
import { getHealthGoalsIcon } from "../../utils/getHealthGoalsIcon";
import { getSymptomsSvg } from "../../utils/getSymptomSvg";
import CompositionMobile from "./TabsMobile/CompositionMobile";
import ProductFieldsOfActionMobile from "./TabsMobile/FieldsOfActionMobile";
import getProductAlertsMobile from "./components/AlertsMobile";
import { CardMobile } from "./components/CardMobile";
import CardSimpleMobile from "./components/CardSimpleMobile";
import { SymptomNutrientsModalMobile } from "./components/SymptomNutrientsModalMobile";
import TabViewMobile from "./components/TabViewMobile";

export type ProductViewType = {
  state: ProductProps;
};

const ProductDetailsMobile: React.FC = () => {
  const { t } = useTranslation();

  const { state: product } = useLocation() as ProductViewType;

  const navigation = useNavigate();
  const sliderRef = createRef<HTMLDivElement>();

  const [openSymptomModal, setOpenSymptomModal] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState<
    SymptomType | undefined
  >();
  const [amountToBuy, setAmountToBuy] = useState(1);
  const [productDurationInDays, setProductDurationInDays] = useState(0);

  const {
    addProduct,
    isProductAlreadyAdded,
    handleOpenRemoveProductModal,
    getAmountOfProduct,
    isProductAlreadyAddedSameAmount,
  } = useContext(ShoppingCartContext);

  const isInCart = () => isProductAlreadyAddedSameAmount(product, amountToBuy);

  useEffect(() => {
    setProductDurationInDays(
      (product.capsuleAmount / product.capsuleDosage) * amountToBuy
    );
  }, [amountToBuy]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 50);
    // window.scrollTo(0, 0);
  }, []);

  const addProductInShoppingCart = () => {
    if (isInCart()) {
      handleOpenRemoveProductModal(product);
    } else {
      addProduct(product, amountToBuy);
    }
  };

  const handleOpenSymptomModal = (symptom: SymptomType) => {
    setSelectedSymptom(symptom);
    setOpenSymptomModal(true);
  };

  const handleCloseSymptomModal = () => {
    setSelectedSymptom(undefined);
    setOpenSymptomModal(false);
  };

  const shouldRenderAlertsAndPrecautions = () => {
    return (
      product.alerts.contraindications.length > 0 ||
      product.alerts.excipientAllergy.length > 0 ||
      product.alerts.excipientIntolerance.length > 0 ||
      product.alerts.drugInteraction.length > 0 ||
      product.alerts.componentsAllergy.length > 0 ||
      product.alerts.componentsIntolerance.length > 0
    );
  };

  // const RenderHeaderLogo = () => (
  //   <HeaderContainer>
  //     <img src={logoMobile} alt="" />
  //   </HeaderContainer>
  // );

  const handleBack = useCallback(() => {
    navigation(-1);
  }, [navigation]);

  const renderHeader = () => (
    <HeaderContainerMobile>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton onClick={handleBack}>
          <AiOutlineArrowLeft size={25} fill="#fff" />
        </BackButton>
      </div>
    </HeaderContainerMobile>
  );

  const compositionTabRef = useRef<HTMLDivElement>(null);
  const executeCompositionTabScroll = () => {
    if (compositionTabRef && compositionTabRef.current) {
      compositionTabRef.current.scrollIntoView();
    }
  };

  const handleOnClickMore = () => {
    setAmountToBuy(amountToBuy + 1);
  };

  const handleOnClickLess = () => {
    amountToBuy > 1 && setAmountToBuy(amountToBuy - 1);
  };

  const handleOnChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setAmountToBuy(Number(e.target.value));
  };

  const renderHeaderLogo = () => (
    <HeaderContainer>{/* <img src={logoMobile} alt="" /> */}</HeaderContainer>
  );

  return (
    <Page
      screen=""
      header={renderHeader}
      logo={renderHeaderLogo()}
      removePadding
    >
      <CardMobile
        product={product}
        amountToBuy={amountToBuy}
        productDurationInDays={productDurationInDays}
        handleOnClickMore={handleOnClickMore}
        handleOnClickLess={handleOnClickLess}
        handleOnChangeInput={handleOnChangeInput}
      />

      {shouldRenderAlertsAndPrecautions() && (
        <AlertsContainer>
          <CardSimpleMobile title={t("common.alerts")}>
            <HorizontalScrollView padding={0} gap={8}>
              {getProductAlertsMobile({
                alerts: product.alerts,
                t,
              })}
            </HorizontalScrollView>
          </CardSimpleMobile>
        </AlertsContainer>
      )}
      {product.healthGoals.filter((elem) => elem.score !== 0).length > 0 && (
        <CardSimpleMobile title={t("screens.productDetail.health_goals")}>
          <HealthGoalsContainer>
            {product.healthGoals.map((healthGoal, index) => {
              if (healthGoal.score === 0) {
                return;
              }

              return (
                <ProgressCard
                  key={index}
                  title={healthGoal.name}
                  icon={getHealthGoalsIcon(healthGoal.name, 40)}
                  progress={healthGoal.score}
                  height={10}
                />
              );
            })}
          </HealthGoalsContainer>
        </CardSimpleMobile>
      )}

      {product.symptoms.length > 0 && (
        <CardSimpleMobile
          title={`${product.symptoms.length} ${t(
            "screens.productDetail.symptoms"
          )}`}
        >
          {/* <SymptomScrollButtonContainer>
          <ScrollButtons ref={sliderRef} length={174} />
        </SymptomScrollButtonContainer> */}
          <HorizontalScrollView ref={sliderRef} size={180} gap={8}>
            {product.symptoms.map((symptom, index) => {
              return (
                <Symptom
                  //   warning={index % 2 === 1}
                  key={index}
                  icon={getSymptomsSvg(symptom.categoryId, "white")}
                  progress={symptom.score}
                  title={symptom.name}
                  onClick={() => handleOpenSymptomModal(symptom)}
                  warning={symptom.score < 0}
                />
              );
            })}
          </HorizontalScrollView>
        </CardSimpleMobile>
      )}
      <div ref={compositionTabRef} />
      <CompositionTabContainer>
        <TabViewMobile
          numberOfComponentesLabel={[
            product.composition.length,
            product.fieldsOfAction.length,
          ]}
          labels={[
            t("screens.productDetail.composition"),
            t("screens.productDetail.fields_of_action"),
          ]}
          contents={[
            <CompositionMobile
              composition={product.composition}
              executeScroll={executeCompositionTabScroll}
              excipients={product.excipients}
              otherIngredients={product.otherIngredients}
            />,
            <ProductFieldsOfActionMobile
              nutritionDeficiency={product.nutritionDeficiency}
              fieldsOfAction={product.fieldsOfAction}
            />,
          ]}
        />
      </CompositionTabContainer>

      <div style={{ marginTop: "64px" }} />
      <SymptomNutrientsModalMobile
        isOpen={openSymptomModal}
        symptom={selectedSymptom}
        onClose={handleCloseSymptomModal}
      />

      <Footer>
        <HoverButton
          width={200}
          height={40}
          sizeFont={16}
          isActive={isInCart()}
          label={
            isInCart()
              ? t("screens.productDetail.in_cart")
              : `${t("screens.productDetail.add_to_cart")}: ${getCurrencyFormat(
                  product.currency,
                  (amountToBuy - getAmountOfProduct(product)) * product.price
                )}`
          }
          onClick={addProductInShoppingCart}
        />
      </Footer>
    </Page>
  );
};

export default ProductDetailsMobile;
