import styled, { css, DefaultTheme } from "styled-components";

interface ContainerProps {
  elements?: number;
}

export const getColorByQuartile = (score: number, theme: DefaultTheme) => {
  if (score <= 25) {
    return "#D24848";
  } else if (score <= 50) {
    return "#F68E61";
  } else if (score <= 75) {
    return "rgba(255, 198, 113, 0.97)";
  } else {
    return theme.colors.selected;
  }
};

const iconBox = css`
  height: 32px;
  width: 32px;
  // margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 17.78px;
    width: 17.78px;
  }
`;

export const Container = styled.div<ContainerProps>`
  max-width: 280px;
  min-width: 280px;
  /* min-height: 382px; */
  max-height: 382px;
  height: 360px;
  width: 280px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.white};

  // border: 2px solid red;
  cursor: pointer;

  > * {
    cursor: pointer;
  }
`;

export const Nutrient = styled.img`
  height: 100px;
  width: auto;
  image-rendering: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ScrollContainer = styled.div`
  height: 350px;
  width: auto;
  > .scroll-horizontal {
    overflow-x: scroll !important;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // align-items: flex-end;
  padding: 8px;
  // padding-top: 4px;
  // padding-bottom: 4px;
  width: 100%;
  flex-flow: wrap;
  position: relative;
  height: 100%;
  // border: 2px solid orange;

  > img {
    max-width: 100px;
  }
`;
interface StarContainerProps {
  isTheMostRecommended?: boolean;
}

export const StarContainer = styled.div<StarContainerProps>`
  ${iconBox}
  ${({ theme, isTheMostRecommended }) => isTheMostRecommended ? theme.goldGradient : theme.blueGradient}
  /* ${({ theme }) => theme.blueGradient} */
`;

export const AlertContainer = styled.div.attrs(
  (props: { isInPack: boolean }) => props
)`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
  ${(props) => props.isInPack && `margin-top: 4px;`}
`;

export const NutrientInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  // border: 2px solid red;
  gap: 2px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  // border: 2px solid red;
`;

export const NutrientName = styled.label`
  font-size: 18px;
  margin: 4px 0 8px 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  height: 44px;
  width: 250px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  > span {
    font-size: 14px;
    font-weight: 400;
    flex-direction: row-reverse;
    color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const NutrientCompability = styled.span<{ value: number }>`
  margin-left: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  //   margin-top: 4px;
  color: ${({ theme }) => theme.colors.black};
  > span {
    margin-left: 6px;
    color: ${({ value, theme }) => getColorByQuartile(value, theme)};
    font-weight: bold;
  }
  cursor: pointer;
`;

export const HealthGoalAndSymptomTitle = styled.label`
  //   border: 2px solid green;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  > * {
    cursor: pointer;
  }
`;

export const HealthGoalAndSymptomContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  :not(:first-child) {
    margin-top: 16px;
  }

  > div {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

export const Subtitle = styled.label`
  margin-top: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  // set max 1 line
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.colors.gray};

  opacity: 0.6;
`;

export const HealthGoalsAndSymptomsContainer = styled.div`
  //   margin-top: 1em;
  margin-top: 10px;
  background-color: #f5f7fe;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`;

export const HealthGoalAndSymptomScoreProgress = styled.div`
  // // border: 2px solid red;
  // width: 44px;
  // height: 44px;
  // border-radius: 50%;
`;

export const HealthGoalsAndSymptomsIcon = styled.div`
  //   border: 2px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: auto;
  height: 100%;

  > * {
    width: 20px;
    height: auto;
  }

  //   > * {
  //     border: 2px solid green;
  //   }
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    // line-height: 39px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding-bottom: 2px;

  // border: 2px solid red;


  > div {
    width: 50%;
    display: flex;
    align-items: center;

  > button {
    width: 100%;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.selected};
    padding: 6px;
    justify-content: center;
    align-items: center;
    // border: 2px solid green;
    display: flex;


    > svg {
      // border: 2px solid red;
      margin-left: 6px;
      margin-bottom: 1px;
      fill: white;
    }

    &:hover {
      background: linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%);
    }
  }
}
`;

export const NoneTitle = styled.div`
  width: 65px;
  height: 6px;
  background: #54597650;
  border-radius: 15px;
`;

export const NoneSubtitle = styled.div`
  width: 65px;
  height: 6px;
  background: #54597640;
  border-radius: 15px;
  margin-top: 8px;
`;

export const ButtonLabel = styled.label<{
  textLength: number;
}>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  // border: 2px solid red;

  ${({ textLength }) => {
    if (textLength > 15) {
      return `
        font-size: 14px;
        `;
    }
  }}
`;

export const LearnMoreLabel = styled.label<{
  textLength: number;
}>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.selected};
  cursor: pointer;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;

  :hover {
    text-decoration: underline;
  }

  ${({ textLength }) =>
    textLength > 15 &&
    `
        font-size: 14px;
    `}
`;
