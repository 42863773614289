import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import scrollbar from "../../../../styles/scrollbar";

export const BackDrop = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: -998;
  background: #00000060;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;

  ${({ open }) =>
    open &&
    `
  z-index:998;
  opacity: 1;
  `}
`;

export const Container = styled.div<{ open: boolean }>`
  width: 100%;
  /* position: fixed;
  right: -734px;
  top: 0; */
  background: white;
  height: 100%;
  /* z-index: 999; */
  box-shadow: -8px 0px 8px rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 428px) {
    width: 100%;
  }

  // border: 2px solid red;

  transition: all 0.6s ease-out;
  right: 0;

  ${({ open }) =>
    open &&
    `
    right: 0;
  `}

  > svg {
    margin: 10px 0 0 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  // border: 2px solid green;
`;

export const BasketTitle = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  margin-left: 20px;

  color: ${({ theme }) => theme.colors.gray};
`;

export const NutritionalNeeds = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: #f5f7fe;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin: 1rem;
`;

export const NutritionalNeedsContent = styled.div`
  display: flex;
  flex-direction: column;
  //   border: 2px solid red;
  width: 100%;
  margin-left: 8px;
`;

export const NutritionalNeedsTitle = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
  /* color: ${({ theme }) => theme.colors.gray}; */
  font-weight: 400;

  /* > label {
    color: ${({ theme }) => theme.colors.blueLight};
    font-weight: 700;
  } */
`;

export const Footer = styled.footer`
  /* padding: 9px 24px 16px 24px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f5f7fe; */
  position: fixed;
  /* position: relative; */
  bottom: 0;
  width: 100%;
  left: 0;
  /* box-shadow: 0px -9px 16px #d3d6df; */

  height: 70px;
  background: linear-gradient(360deg, #00A984 -35.57%, #0AE2D5 91.4%);
  box-shadow: 0px -9px 16px rgba(140, 140, 140, 0.4);
`;

export const SeparateLine = styled.div`
  width: 100%;
  height: 1px;
  background: #d3d6df;
  margin: 8px 0 16px 0;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding: 1rem;

  @media screen and (max-width: 428px) {
    padding: 0 10px 50px 5px;
  }

  /* @media screen and (max-width: 375px) {
    padding: 0 8px 100px 0;
  } */

  /* padding: 5px 5px 30px 5px; */
  // border: 2px solid red;
  ${scrollbar}
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: auto;

  @media screen and (max-width: 428px) {
    background: #fff;
    padding: 8px 24px;
  }
`;

export const QRcodePriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 428px) {
    /* background: #fff; */
    padding: 8px 24px;
  }
`;

export const QRcodeTotalLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.white};
`;

export const TotalLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.white};
`;

export const QRcodePriceLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.white};
`;

export const PriceLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // line-height: 39px;
  color: #00A984;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0 1rem; 
`;

export const CloseAndTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  padding: 6px;
`;
