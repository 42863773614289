import { FC, SVGProps, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router";
import { ReactComponent as MediMarketIcon } from "../../../assets/pharmacy-logos/pharmacy-13-loading-sidebar.svg";
import { ReactComponent as CompleteMediMarketLogo } from "../../../assets/pharmacy-logos/pharmacy-13-medi-market-margin.svg";
import { FilterContext } from "../../../context/filter";
import { GlobalContext } from "../../../context/global";
import { SidebarContext } from "../../../context/sidebar";
import { sidebarElements } from "../../../utils/constants/sidebarElements";
import {
  Container,
  Content,
  Element,
  ElementContainer,
  LogoContainer,
  SidebarAllContent,
  Title,
  Toogle,
} from "./style";

type NavSidebarElement = {
  key: string;
  title: string;
  path: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  filled: boolean;
};

const Sidebar = ({ screen }: { screen: string }) => {
  const navigate = useNavigate();
  const { processingAnswer } = useContext(GlobalContext);

  const { t } = useTranslation();

  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  const { clearFilters } = useContext(FilterContext);

  const navigateToScreen = (item: NavSidebarElement) => {
    sessionStorage.removeItem("home_best_nutrients_scroll_position");
    sessionStorage.removeItem("home_best_products_scroll_position");
    sessionStorage.removeItem("nutrient_tab");
    clearFilters();

    if (processingAnswer) {
      return;
    } else {
      navigate(item.path);
    }
  };

  const toggleSidebar = () => {
    if (!processingAnswer) setSidebarOpen(!sidebarOpen);
  };

  return (
    <Container opened={sidebarOpen}>
      <SidebarAllContent>
        <Toogle onClick={toggleSidebar}>
          {sidebarOpen ? (
            <BsChevronLeft size={20} />
          ) : (
            <BsChevronRight size={20} />
          )}
        </Toogle>
        <Content>
          <LogoContainer sidebarOpen={sidebarOpen} textLength={0}>
            {/* <BigLogoIcon className="big-logo-class" />
            <LogoIcon className="small-logo-class" /> */}
            <MediMarketIcon className="logo-class" />
            <CompleteMediMarketLogo className="complete-logo-class" />
          </LogoContainer>
          <ElementContainer opened={sidebarOpen}>
            {sidebarElements.map((item) => (
              <Element
                sidebarOpen={sidebarOpen}
                key={item.path}
                onClick={() => {
                  navigateToScreen(item);
                }}
                filled={screen === item.key}
              >
                <item.icon />
                <Title sidebarOpen={sidebarOpen} filled={screen === item.key}>
                  {t(item.title)}
                </Title>
              </Element>
            ))}
          </ElementContainer>
        </Content>
      </SidebarAllContent>
    </Container>
  );
};

export { Sidebar };
