import styled, { css } from "styled-components";

interface SidebarProps {
  opened: boolean;
}

const elementFlag = css`
  content: "";
  position: absolute;
  width: 7px;
  border-radius: 16px;
  height: 39px;
  left: -3px;
  background-color: ${({ theme }) => theme.colors.selected};
  display: block;
  top: 50%;
  transform: translate(0, -50%);
`;

export const Container = styled.aside<SidebarProps>`
  width: ${({ opened }) => (opened ? "274" : "80")}px;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  transition: width 500ms;
  // border: 2px solid red;

  @media screen and (min-width: 1714px) {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 90;
  }
`;
export const SidebarAllContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border: 2px solid red;
`;

export const LogoContainer = styled.div<{
  sidebarOpen: boolean;
  textLength: number;
}>`
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 3.5rem;
  position: relative;
  overflow: hidden;
  transition-timing-function: ease;

  // border: 2px solid red;

  // > svg {
  // }

  .logo-class {
    position: absolute;
    // border: 2px solid blue;
    position: absolute;
    height: auto;
    width: 200px;
    left: 12px;
  }

  .complete-logo-class {
    flex-shrink: 0;
    height: auto;
    width: 200px;
    position: absolute;
    left: 12px;
    overflow: visible;

    opacity: 0;
    ${({ sidebarOpen }) => sidebarOpen && ` opacity: 1;`}
  }
`;

export const Title = styled.label<{ filled: boolean; sidebarOpen: boolean }>`
  color: ${({ theme, filled }) =>
    filled ? theme.colors.selected : theme.colors.black};
  font-weight: ${({ filled }) => filled && "bold"};
  align-self: center;
  justify-self: center;
  padding-left: 18px;
  cursor: pointer;
  height: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: opacity 200ms;

  opacity: ${({ filled }) => (filled ? 1 : 0.5)};

  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;

  ${({ sidebarOpen }) =>
    !sidebarOpen &&
    `
    opacity: 0;
  `}
`;

export const Toogle = styled.button`
  width: 100%;
  padding: 1rem;
`;

export const ElementContainer = styled.div<SidebarProps>`
  width: 100%;
  cursor: pointer;
  // border: 2px solid blue;
  > div {
    // justify-content: ${({ opened }) => (!opened ? "center" : "left")};
    // align-content: ${({ opened }) => (!opened ? "center" : "left")};
  }
`;

export const Element = styled.div<{ filled: boolean; sidebarOpen: boolean }>`
  display: flex;
  width: 100%;
  cursor: pointer;
  // border: 2px solid orange;
  padding: 20px;
  padding-left: 26px;
  padding-right: 0px;
  position: relative;

  :before {
    ${({ filled }) => filled && elementFlag}
  }

  > svg {
    height: 25px;
    width: auto;
    flex-shrink: 0;

    transition: all 200ms;
    // border: 2px solid green;
  }
  > svg * {
    fill: ${({ filled, theme }) =>
      filled ? theme.colors.selected : "#A9ACBB"} !important;
  }

  &:hover {
    :before {
      ${elementFlag}
    }

    svg * {
      fill: ${({ theme, filled }) =>
        filled ? theme.colors.selected : theme.colors.black} !important;
    }

    > label {
      ${({ sidebarOpen }) => sidebarOpen && `opacity: 1`}
    }
  }
`;
