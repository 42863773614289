import styled from "styled-components";

export const HoverButtonContainer = styled.button<{
  isAdded: boolean;
  cursorNotAllowed: boolean;
  width?: number;
  height?: number;
  sizeFont?: number;
  margin?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : "250px")};
  ${({ height }) => height && `height: ${height}px`};
  /* height: ${({ height }) => (height ? `${height}px` : "250px")}; */
  display: flex;
  justify-content: ${({ isAdded }) => (isAdded ? "center" : "space-between")};
  gap: ${({ isAdded }) => (isAdded ? "20px" : "")};
  /* justify-content: space-between; */
  background-color: ${({ isAdded, theme }) =>
    !isAdded && `${theme.colors.selected}`};
  align-items: center;
  padding: 0 30px;
  margin: ${({ margin }) => (margin ? `${margin}px` : "")};
  border-radius: 80px;
  cursor: ${({ cursorNotAllowed }) =>
    cursorNotAllowed ? "not-allowed" : "pointer"};
  position: relative;

  > svg {
    width: 25px;
    height: 25px;
    // border: 2px solid red;
    /* margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
    position: absolute;
    right: 12px; */
  }

  > label {
    cursor: pointer;
    font-size: ${({ sizeFont }) => (sizeFont ? sizeFont : 18)}px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    cursor: ${({ cursorNotAllowed }) =>
      cursorNotAllowed ? "not-allowed" : "pointer"};
  }

  /* transition: all 0.3s; */

  &:hover {
    background: ${({ isAdded }) =>
      !isAdded && `linear-gradient(180.32deg, #0fa986 15.34%, #007acc 98.15%)`};
  }

  ${({ isAdded, theme }) => isAdded && `background: ${theme.colors.midBlue}`}
`;

export const IconContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  > svg * {
    width: 100%;
    height: 100%;
    stroke: #fff;
  }
`;
