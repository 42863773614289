import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoAlertOutline, IoCalendarOutline } from "react-icons/io5";
import { ProductViewType } from "../..";
import { ReactComponent as CapsuleIcon } from "../../../../assets/content/capsule.svg";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import { ReactComponent as SunriseIcon } from "../../../../assets/content/sunrise.svg";
import { ReactComponent as CapsuleIntakeIcon } from "../../../../assets/icons/svg/capsuleIcon.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import HoverButton from "../../../../components/Button/HoverButton";
import { MoreOrLessInput } from "../../../../components/Input/MoreOrLess";
import StandardTooltip from "../../../../components/Tooltip";
import TooltipAlert from "../../../../components/TooltipAlert";
import { ShoppingCartContext } from "../../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";
import handleImportance from "../../../../utils/getImportance";
import { handleColorTooltipRecommended } from "../../../../utils/handleColorTooltipRecommended";
import { handleIsTheMostRecommended } from "../../../../utils/handleIsTheMostRecomended";
import {
  AlertContainer,
  BrandName,
  CapsuleContainer,
  CardContainer,
  CardContent,
  CardHeader,
  ImageContainer,
  InfoContainer,
  MedicineTimeContainer,
  PriceContainer,
  PriceText,
  ProductIconsContainer,
  ProductName,
  ProductsButtonsContainer,
  StarContainer,
  TitleContainer,
} from "./style";

const Card: React.FC<ProductViewType> = ({ state: product }) => {
  const [amountToBuy, setAmountToBuy] = useState(1);
  const {
    addProduct,
    isProductAlreadyAddedSameAmount,
    handleOpenRemoveProductModal,
    getAmountOfProduct,
  } = useContext(ShoppingCartContext);

  const [productDurationInDays, setProductDurationInDays] = useState(0);

  const getDuration = () => {
    let months = Math.floor(productDurationInDays / 30);
    let weeks = Math.floor(productDurationInDays / 7) - months * 4;

    if (weeks >= 4) {
      weeks -= 4;
      months += 1;
    }

    if (months > 0 && weeks > 0) {
      return `${months} ${
        months > 1
          ? t("screens.productDetail.months")
          : t("screens.productDetail.month")
      } ${t("screens.productDetail.and")} ${weeks} ${
        weeks > 1
          ? t("screens.productDetail.weeks")
          : t("screens.productDetail.week")
      }`;
    } else if (months > 0) {
      return `${months} ${
        months > 1
          ? t("screens.productDetail.months")
          : t("screens.productDetail.month")
      }`;
    } else if (weeks > 0) {
      return `${weeks} ${
        weeks > 1
          ? t("screens.productDetail.weeks")
          : t("screens.productDetail.week")
      }`;
    }
  };

  useEffect(() => {
    setProductDurationInDays(
      (product.capsuleAmount / product.capsuleDosage) * amountToBuy
    );
  }, [amountToBuy]);

  const { t } = useTranslation();

  const isInCart = () => isProductAlreadyAddedSameAmount(product, amountToBuy);

  const addProductInShoppingCart = () => {
    if (isInCart()) {
      handleOpenRemoveProductModal(product);
    } else {
      addProduct(product, amountToBuy);
    }
  };

  const handleOnClickMore = () => {
    setAmountToBuy(amountToBuy + 1);
  };

  const handleOnClickLess = () => {
    amountToBuy > 1 && setAmountToBuy(amountToBuy - 1);
  };

  const handleOnChangeInput = (e: any) => {
    setAmountToBuy(Number(e.target.value));
  };

  const {
    brand,
    name,
    score,
    capsuleAmount,
    capsuleDosage,
    currency,
    deliveryMethod, // TODO use this to change the icon
    hasPrecaution,
    hasSynergy,
    isInPack,
    imageUrl,
    intakeSuggestion,
    price,
    alerts,
  } = product;

  const hasDosageInfo = () => {
    return !!capsuleDosage && !!capsuleAmount;
  };

  return (
    <CardContainer horizontal>
      <ImageContainer>
        <img
          src={getImageCompletePath("product", imageUrl)}
          alt="product"
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
      </ImageContainer>
      <CardContent>
        <CardHeader>
          <TitleContainer>
            <BrandName>{brand}</BrandName>
            <ProductName>{name}</ProductName>
            {/* <Compatibility value={Math.round(score)}>
              {t("screens.productDetail.your_compatibility")}
              <span>{`${Math.round(score)}/100`}</span>
            </Compatibility> */}
          </TitleContainer>
          <ProductIconsContainer>
            <CapsuleContainer>
              <CapsuleIcon />
              <label>{capsuleAmount}</label>
            </CapsuleContainer>

            {(isInPack || score >= 80) && (
              <StarContainer
                id={`product-is-in-top-ten-${product.id}`}
                isTheMostRecommended={handleIsTheMostRecommended(score)}
              >
                <StarIcon />
              </StarContainer>
            )}
            {(isInPack || score >= 80) && (
              <StandardTooltip
                anchor={`product-is-in-top-ten-${product.id}`}
                color={handleColorTooltipRecommended(score)}
                text={
                  score >= 80
                    ? t("hovers.product_is_highly_recommended")
                    : t("hovers.product_is_recommended")
                }
                place="bottom"
              />
            )}
            {hasPrecaution && (
              <AlertContainer
                id={`product-has-alert-${product.id}`}
                importance={handleImportance(product.alerts)}
              >
                <IoAlertOutline color="white" />
              </AlertContainer>
            )}
            {hasPrecaution && (
              <TooltipAlert
                anchor={`product-has-alert-${product.id}`}
                color={handleImportance(product.alerts)}
                text={t("hovers.product_has_alert")}
                place="bottom"
              />
            )}
          </ProductIconsContainer>
        </CardHeader>
        <MedicineTimeContainer>
          {!!intakeSuggestion && (
            <div>
              <SunriseIcon />
              {/* // TODO ADICIONAR TRADUÇÃO AQUI */}
              <label>{intakeSuggestion.en_text}</label>
            </div>
          )}
          {hasDosageInfo() && (
            <div>
              <CapsuleIntakeIcon />
              <label>
                {capsuleDosage > 1
                  ? t("screens.productDetail.capsules_per_day", {
                      count: capsuleDosage,
                    })
                  : t("screens.productDetail.capsule_per_day", {
                      count: capsuleDosage,
                    })}
              </label>
            </div>
          )}
        </MedicineTimeContainer>
        {hasDosageInfo() && (
          <MedicineTimeContainer>
            <InfoContainer>
              <IoCalendarOutline />
              <label>{`${t(
                "screens.recommendedPack.duration"
              )}: ${getDuration()}`}</label>
            </InfoContainer>
          </MedicineTimeContainer>
        )}
        <PriceContainer>
          <ProductsButtonsContainer>
            <MoreOrLessInput
              actualValue={amountToBuy}
              onChangeInput={handleOnChangeInput}
              onClickLess={handleOnClickLess}
              onClickMore={handleOnClickMore}
            />
          </ProductsButtonsContainer>

          <PriceText>{`${getCurrencyFormat(
            currency,
            amountToBuy * price
          )}`}</PriceText>

          <HoverButton
            isActive={isInCart()}
            label={
              isInCart()
                ? t("screens.productDetail.in_cart")
                : `${t("screens.productDetail.add_to_cart")}:  ${
                    amountToBuy - getAmountOfProduct(product)
                  }`
            }
            onClick={addProductInShoppingCart}
          />
        </PriceContainer>
      </CardContent>
    </CardContainer>
  );
};

export { Card };
